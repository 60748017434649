<template>
  <div>
    <div class="custom-navbar">
      <div class="left-section">
        <div class="navbar-content">
          <img
            src="/images/left-icon.png"
            @click="
              () => {
                gotoBackComp();
              }
            "
          />
        </div>
      </div>
      <div class="middle-section">
        <h2>Add Address Detail</h2>
      </div>
    </div>
    <div class="add-address-detail-container">
      <div>
        <div class="delivery-status">
          <div class="map-container-div">
            <input
              id="pac-input"
              class="controls add-address-input"
              type="text"
              placeholder="Enter a location"
            />
            <div
              id="map"
              @touchstart.prevent="preventPullToRefresh"
              @touchmove.prevent="preventPullToRefresh"
              @touchend.prevent="allowPullToRefresh"
            ></div>
          </div>
        </div>

        <InputField
          :id="'buildingAddress'"
          class="input-class"
          :height="'40px'"
          :color="'#C59B31'"
          :value="buildingAddress"
          :type="'text'"
          :placeholder="'Enter Building Address'"
          @change-handler="
            ($event) => {
              buildingAddress = $event;
            }
          "
        />
        <InputField
          :id="'streetAddress'"
          class="input-class"
          :height="'40px'"
          :type="'text'"
          :value="streetAddress"
          :width="'100%'"
          :color="'#C59B31'"
          :placeholder="'Enter Street Address'"
          @change-handler="
            ($event) => {
              streetAddress = $event;
            }
          "
        />
        <InputField
          :id="'pinCode'"
          class="input-class"
          :height="'40px'"
          :type="'text'"
          :value="pinCode"
          :maxLength="'6'"
          :width="'100%'"
          :color="'#C59B31'"
          :placeholder="'Enter PIN code'"
          @change-handler="
            ($event) => {
              pinCode = $event;
            }
          "
        />
        <InputField
          :id="'landmark'"
          class="input-class"
          :height="'40px'"
          :type="'text'"
          :value="landmark"
          :width="'100%'"
          :color="'#C59B31'"
          :placeholder="'Enter Landmark'"
          @change-handler="
            ($event) => {
              landmark = $event;
            }
          "
        />
        <InputField
          :id="'addressName'"
          class="input-class"
          :height="'40px'"
          :type="'text'"
          :width="'100%'"
          :color="'#C59B31'"
          :placeholder="'Save Address as Home, Office, Other..'"
          :value="addressName"
          @change-handler="
            ($event) => {
              addressName = $event;
            }
          "
        />
        <ButtonComp
          :id="'addAddressDetail'"
          class="submit-button"
          :value="addressButtTitle"
          :height="'40px'"
          :width="'100%'"
          :color="'#C59B31'"
          @clickEvent="storeAddress()"
          @change-handler="
            ($event) => {
              avgPrice = $event;
            }
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
// import { errorAlert, infoAlert } from "@/utility";
import PullToRefresh from "pulltorefreshjs";

export default {
  data() {
    return {
      center: { lat: 22.774, lng: 88.19 },
      startLocation: {},
      endLocation: {},
      streetAddress: "",
      buildingAddress: "",
      mapApiKey: process.env.VUE_APP_MAP_API_KEY,
      landmark: "",
      addressName: "",
      cityName: "",
      stateName: "",
      pinCode: "",
      text: "",
      addressButtTitle: "Add Address",
      mapOptions: {
        mapTypeControl: false,
        styles: [
          {
            featureType: "water",
            elementType: "geometry",
            stylers: [{ color: "#e9e9e9" }, { lightness: 17 }],
          },
          {
            featureType: "landscape",
            elementType: "geometry",
            stylers: [{ color: "#f5f5f5" }, { lightness: 20 }],
          },
        ],
      },
      markers: [
        {
          position: {
            lat: 0,
            lng: 0,
          },
        },
      ],
    };
  },
  components: {},
  props: {
    show: { type: Boolean, default: true },
    prefill: { type: Object, default: null },
  },
  computed: {},
  created() {
    PullToRefresh.destroyAll(true);
    if (this.prefill) {
      this.markers[0].position = {
        lat: this.prefill?.location.coordinates[0],
        lng: this.prefill?.location.coordinates[1],
      };
      this.addressButtTitle = "Confirm Address";
      this.center.lat = this.prefill?.location.coordinates[0];
      this.center.lng = this.prefill?.location.coordinates[1];
      this.completeAddress = this.prefill.completeAddress;
      this.buildingAddress = this.prefill.buildingAddress;
      this.streetAddress = this.prefill.streetAddress;
      this.landmark = this.prefill.landmark;
      this.pinCode = this.prefill.pinCode;
      this.addressName = this.prefill.addressName;
      this.initMap();
    } else {
      navigator.geolocation.getCurrentPosition((position) => {
        this.center.lat = position.coords.latitude;
        this.center.lng = position.coords.longitude;
        if (position.coords.latitude) {
          this.markers[0].position = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
        }
        this.initMap();
      });
      this.initMap();
    }
  },
  methods: {
    allowPullToRefresh() {
      document.body.style.overflow = "auto";
    },
    gotoBackComp() {
      this.$emit("goback");
    },
    async initMap() {
      let self = this;
      const icon = {
        url: "https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/geocode-71.png",
        size: new window.google.maps.Size(71, 71),
        origin: new window.google.maps.Point(0, 0),
        anchor: new window.google.maps.Point(10, 34),
        scaledSize: new window.google.maps.Size(25, 25),
      };

      const { Map } = await window.google.maps.importLibrary("maps");
      let map = new Map(document.getElementById("map"), {
        center: this.center,
        zoom: 15,
        gestureHandling: "greedy",
        mapTypeControl: false,
        fullscreenControl: false,
        styles: [
          {
            featureType: "poi",
            stylers: [{ visibility: "off" }],
          },
        ],
      });

      const input = document.getElementById("pac-input");
      const searchBox = new window.google.maps.places.SearchBox(input);

      map.addListener("bounds_changed", () => {
        searchBox.setBounds(map.getBounds());
      });

      let marker = new window.google.maps.Marker({
        icon,
        position: map.getCenter(),
        draggable: true,
        map: map,
      });

      map.addListener("click", (event) => {
        self.markers[0].position = {
          lat: event.latLng.lat(),
          lng: event.latLng.lng(),
        };
        marker.setPosition(event.latLng);
      });

      window.google.maps.event.addListener(marker, "dragend", function (event) {
        self.markers[0].position = {
          lat: event.latLng.lat(),
          lng: event.latLng.lng(),
        };
      });

      searchBox.addListener("places_changed", () => {
        const places = searchBox.getPlaces();

        if (places.length == 0) {
          return;
        }

        // For each place, get the icon, name, and location.
        const bounds = new window.google.maps.LatLngBounds();
        places.forEach((place) => {
          if (!place.geometry || !place.geometry.location) {
            return;
          }

          self.markers[0].position = {
            lat: places[0].geometry.location.lat(),
            lng: places[0].geometry.location.lng(),
          };
          marker.setPosition(places[0].geometry.location);

          if (place.geometry.viewport) {
            // Only geocodes have viewport.
            bounds.union(place.geometry.viewport);
          } else {
            bounds.extend(place.geometry.location);
          }
        });
        map.fitBounds(bounds);
      });
    },
    handleMapClick(event) {
      let latitude = event.latLng.lat();
      let longitude = event.latLng.lng();
      this.markers[0].position = {
        lat: latitude,
        lng: longitude,
      };
    },
    setCityState() {
      if (this.pinCode.length === 6) {
        let y = this.pinCode;
        let r = y.slice(0, 6);
        this.pinCode = r;
      }
    },
    onMarkerDragged(event) {
      this.markers[0].position = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };
    },
    async storeAddress() {
      const addressData = {
        streetAddress: this.streetAddress,
        buildingAddress: this.buildingAddress,
        landmark: this.landmark,
        pinCode: this.pinCode,
        addressName: this.addressName,
        location: {
          type: "Point",
          coordinates: [
            this.markers[0].position.lat,
            this.markers[0].position.lng,
          ],
        },
      };

      if (
        addressData.pinCode &&
        addressData.streetAddress &&
        addressData.buildingAddress &&
        addressData.landmark &&
        addressData.addressName
      ) {
        let result = await this.postApiCall("/user/storeCustomerAddress", {
          addressData,
          toUpdateAddress: this.prefill,
        });

        if (result !== "ADDRESS_UPDATED") {
          this.errorAlert("Couldn't updated address");
        } else {
          this.infoAlert("Address Updated");
          // this.backPressed();
        }
        // this.$router.go(-1);
        window.location.reload();
      } else {
        this.errorAlert("Please fill required fields");
      }
    },
    editAddress() {},
  },
  beforeUnmount() {
    PullToRefresh.init({
      mainElement: "body",
      onRefresh() {
        window.location.reload();
      },
    });
  },
};
</script>

<style lang="scss" scoped>
.add-address-detail-container {
  margin: 16px 16px 0px 16px;
}

.custom-navbar {
  display: flex;
  z-index: 1000;
  background-color: #ffffff;
  width: 100%;
  height: 60px;
  top: 0;
  position: sticky;
  align-items: center;
  padding-left: 16px;
}

.add-address-input {
  font-weight: 400;
  padding: 8px 12px;
  width: 100%;
  border-radius: 6px;
  margin: 8px 0 8px 0;
  border: 1px solid #b9c1bd;
  display: block;

  &:focus {
    border: 1px solid #1ba26f;
  }

  &.error {
    color: #b00020;
    border-color: #b00020;
  }
}

#map {
  width: 100%;
  height: 400px;
}

.submit-button {
  margin-bottom: 16px;
}
</style>
