<template>
  <div class="modal-container">
    <modal-component :isScrollable="true">
      <template v-slot:header>
        <p @click="turnModalOff()">x</p>
        <h1>Help us find the best food jonts around your place!</h1>
      </template>
      <template v-slot:body>

        <InputField :id="'eateryName'" class="input-class" :height="'40px'" :type="'text'" :width="'100%'"
          :color="'#C59B31'" :placeholder="'Resturant Name'" @change-handler="($event) => {
              eateryName = $event;
            }
            " />

        <InputField :id="'areaName'" class="input-class" :height="'40px'" :type="'text'" :width="'100%'"
          :color="'#C59B31'" :placeholder="'Enter Area name'" @change-handler="($event) => {
              areaName = $event;
            }
            " />

        <ButtonComp :id="'validate-otp'" :value="'Submit Suggestion'" :width="'100%'" :height="'50px'" :color="'#F90C29'"
          @clickEvent="storeSuggestion()" />
      </template>
    </modal-component>
  </div>
</template>

<script>
import { infoAlert } from '@/utility';

export default {
  name: "StartTableSession",
  data() {
    return {
      show: false,
      eateryName: "",
      areaName: "",
    };
  },
  props: {},
  created() {
  },
  methods: {
    turnModalOff() {
      this.$emit("turnModalOff");
    },
    async storeSuggestion() {
      let result = await this.postApiCall('/user/storeEaterySuggestion', { eateryName: this.eateryName, areaName: this.areaName });
      if (result === 'SUGGESTION_RECIEVED') {
        infoAlert('Thanks for Suggesting');
      }
      window.location.reload();
      // const self = this;
      // Meteor.call("getLiveTables", function (err, result) {
      //   self.liveTables = result;
      // });
    },
  },
};
</script>

<style lang="scss" scoped>
select {
  // font-size: 16px;
  font-weight: 400;
  padding: 8px 12px;
  width: 100%;
  border-radius: 6px;
  margin: 8px 0 0 0;
  border: 1px solid #b9c1bd;
  display: block;

  &:focus {
    border: 1px solid #1ba26f;
  }

  &.error {
    color: #b00020;
    border-color: #b00020;
  }
}

.modal-container {
  height: 500px;
}

.modal-header {
  // font-size: 40px;
}

.order-confirm-item-list {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  margin-bottom: 10px;
  background-color: #f4f4f4;
  border-radius: 5px;
}

.footer-class {
  .total-price {
    display: flex;
    justify-content: space-between;

    p {
      // font-size: 25px;
    }
  }

  .button-strip {
    width: 100%;
    display: flex;
    // width: 200px;

    .button-div {
      margin: 16px 0px 16px 0px;
      width: 100%;
      justify-content: space-between;
    }

    .check-in-button-div {
      margin: 0px 16px 16px 16px;
      width: 100%;
      justify-content: space-between;
    }
  }
}

.timer-class {
  display: flex;
  justify-content: center;

  img {
    width: 100px;
    margin-top: 20px;
  }
}

.table-name {
  display: flex;
  margin-top: 20px;
  justify-content: center;

  .order-placed {
    // font-size: 30px;
  }
}

.reminder {
  display: flex;
  // font-size: 10px;
  justify-content: center;
  margin-bottom: 10px;
}
</style>
