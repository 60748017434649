<template>
  <div class="add-restaurant-container">
    <div class="header">
      <div>
        <p
          class="heading-text"
          :style="{ fontWeight: 'bold', fontSize: '30px' }"
        >
          Edit Restaurant Details
        </p>
      </div>
    </div>
    <div v-if="name !== ''">
      <InputField
        id="restraunt-name"
        placeholder="Restraunt Name"
        :value="name"
        @change-handler="
          ($event) => {
            name = $event;
          }
        "
      />
      <InputField
        id="restraunt-address"
        placeholder="Restraunt Address"
        :value="address"
        @change-handler="
          ($event) => {
            address = $event;
          }
        "
      />
      <InputField
        id="restraunt-avg-price"
        placeholder="Average Price"
        :value="avgPrice"
        @change-handler="
          ($event) => {
            avgPrice = $event;
          }
        "
      />
      <InputField
        id="restraunt-cuisine"
        placeholder="Cuisine Type"
        :value="cuisineType"
        @change-handler="
          ($event) => {
            cuisineType = $event;
          }
        "
      />
      <InputField
        id="restraunt-timing"
        placeholder="Timing"
        :value="timing"
        @change-handler="
          ($event) => {
            timing = $event;
          }
        "
      />
      <InputField
        id="restraunt-timing"
        placeholder="FSSAI Number"
        :value="fssai"
        @change-handler="
          ($event) => {
            fssai = $event;
          }
        "
      />
      <InputField
        id="restraunt-timing"
        placeholder="GST Number"
        :value="gst"
        @change-handler="
          ($event) => {
            gst = $event;
          }
        "
      />
      <InputField
        id="restraunt-latitude"
        placeholder="Latitude"
        :value="lat"
        @change-handler="
          ($event) => {
            lat = $event;
          }
        "
      />
      <InputField
        id="restraunt-longitude"
        placeholder="Longitude"
        :value="lng"
        @change-handler="
          ($event) => {
            lng = $event;
          }
        "
      />
      <div>
        <div
          v-for="(dish, index) in dishList"
          :key="index"
          @click="setDishList(dish)"
        >
          <input
            type="checkbox"
            id="toggle"
            :checked="isDishChecked(dish.tag)"
          />
          {{ dish.name }}
        </div>
      </div>
      <InputField
        id="restraunt-contactNumber"
        placeholder="Contact Number"
        :value="contactNumber"
        @change-handler="
          ($event) => {
            contactNumber = $event;
          }
        "
      />
      <InputField
        id="restraunt-image"
        placeholder="Image"
        :value="image"
        @change-handler="
          ($event) => {
            image = $event;
          }
        "
      />
      <InputField
        id="restraunt-starthour"
        placeholder="Start Hour"
        :value="startHour"
        @change-handler="
          ($event) => {
            startHour = $event;
          }
        "
      />
      <InputField
        id="restraunt-endhour"
        placeholder="End Hour"
        :value="endHour"
        @change-handler="
          ($event) => {
            endHour = $event;
          }
        "
      />
      <InputField
        id="restraunt-buildingaddress"
        placeholder="Building Address"
        :value="buildingAddress"
        @change-handler="
          ($event) => {
            buildingAddress = $event;
          }
        "
      />
      <InputField
        id="restraunt-streetAddress"
        placeholder="Street Address"
        :value="streetAddress"
        @change-handler="
          ($event) => {
            streetAddress = $event;
          }
        "
      />
      <InputField
        id="restraunt-landmark"
        placeholder="Landmark"
        :value="landmark"
        @change-handler="
          ($event) => {
            landmark = $event;
          }
        "
      />
      <InputField
        id="restraunt-pinCode"
        placeholder="Pincode"
        :value="pinCode"
        @change-handler="
          ($event) => {
            pinCode = $event;
          }
        "
      />
      <InputField
        id="restraunt-accountId"
        placeholder="Account Number"
        :value="accountId"
        @change-handler="
          ($event) => {
            accountId = $event;
          }
        "
      />
      <InputField
        id="restraunt-IFSC"
        placeholder="IFSC"
        :value="ifsc"
        @change-handler="
          ($event) => {
            ifsc = $event;
          }
        "
      />
      <InputField
        id="restraunt-IFSC"
        placeholder="rating"
        :value="rating"
        @change-handler="
          ($event) => {
            rating = $event;
          }
        "
      />
      <InputField
        id="restraunt-discounttext"
        placeholder="Discount Text"
        :value="discounttext"
        @change-handler="
          ($event) => {
            discounttext = $event;
          }
        "
      />
      <ButtonComp
        :id="'add-res-butt'"
        :height="'40px'"
        :width="'100%'"
        :color="'#C59B31'"
        :value="'Add Restaurant'"
        @clickEvent="addRestraunt()"
      />
    </div>
  </div>
</template>

<script>
// import { infoAlert } from "../../utility";
import { restaurantTagList } from "../../imports/config.js";

export default {
  name: "AddRestraunt",
  data() {
    return {
      name: "",
      address: "",
      avgPrice: "",
      cuisineType: "",
      timing: "",
      dishList: restaurantTagList,
      fssai: "",
      gst: "",
      lat: "",
      lng: "",
      tags: "",
      contactNumber: "",
      image: "",
      startHour: "",
      endHour: "",
      buildingAddress: "",
      streetAddress: "",
      discounttext: "",
      landmark: "",
      pinCode: "",
      accountId: "",
      ifsc: "",
      rating: "",
    };
  },
  async created() {
    let restaurantId = this.$route.params.restaurantId;
    let restaurantData;
    if (restaurantId === "null") {
      restaurantData = await this.postApiCall("/admin/getRestaurantDetails");
    } else {
      restaurantData = await this.postApiCall("/admin/getRestaurantDetails", {
        restaurantId,
      });
    }

    this.name = restaurantData.name;
    this.address = restaurantData.address;
    this.avgPrice = restaurantData.avgPrice;
    this.cuisineType = restaurantData.cuisineType;
    this.timing = restaurantData.timing;
    this.fssai = restaurantData.fssai;
    this.gst = restaurantData.gst;
    this.lat = restaurantData.location.coordinates[0];
    this.lng = restaurantData.location.coordinates[1];
    this.tags = restaurantData.tags.join(",");
    this.contactNumber = restaurantData.contactNumber;
    this.image = restaurantData.image;
    this.startHour = restaurantData.startHour;
    this.endHour = restaurantData.endHour;
    this.buildingAddress = restaurantData.addressDetails.buildingAddress;
    this.streetAddress = restaurantData.addressDetails.streetAddress;
    this.landmark = restaurantData.addressDetails.landmark;
    this.pinCode = restaurantData.addressDetails.pinCode;
    this.accountId = restaurantData.accountId;
    this.ifsc = restaurantData.ifsc;
    this.rating = restaurantData.rating;
    this.identifier = restaurantData.identifier;
    this.discounttext = restaurantData.discounttext;
  },
  methods: {
    setDishList(item) {
      let currentList = this.tags === "" ? [] : this.tags.split(",");
      if (currentList.includes(item.tag)) {
        const newArray = currentList.filter((obj) => obj !== item.tag);
        this.tags = newArray.join(",");
      } else {
        currentList.push(item.tag);
        this.tags = currentList.join(",");
      }
    },
    isDishChecked(item) {
      let currentList = this.tags === "" ? [] : this.tags.split(",");
      if (currentList.includes(item)) {
        return true;
      }
      return false;
    },
    async addRestraunt() {
      let result = await this.postApiCall("/admin/editRestaurant", {
        identifier: this.identifier,
        name: this.name,
        address: this.address,
        avgPrice: this.avgPrice,
        cuisineType: this.cuisineType,
        timing: this.timing,
        fssai: this.fssai,
        gst: this.gst,
        lat: this.lat,
        lng: this.lng,
        tags: this.tags,
        contactNumber: this.contactNumber,
        image: this.image,
        startHour: this.startHour,
        endHour: this.endHour,
        buildingAddress: this.buildingAddress,
        streetAddress: this.streetAddress,
        landmark: this.landmark,
        pinCode: this.pinCode,
        accountId: this.accountId,
        ifsc: this.ifsc,
        rating: this.rating,
        discounttext: this.discounttext,
      });

      if (result === "RESTAURANT_CREATED") {
        this.infoAlert("Restaurant Updated");
        // this.$router.push("/login");
      } else {
        this.errorAlert("Please check your inputs");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.add-restaurant-container {
  margin: 16px 16px 16px 16px;

  .header {
    .heading-text {
      size: 70px;
      font-weight: 600 !important;
    }
  }
}

p {
  font-family: serif;
}
</style>
