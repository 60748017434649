<template>
  <div class="modal-container">
    <modal-component :isScrollable="true">
      <template v-slot:header>
        <p @click="turnModalOff()">x</p>
        <p class="modal-header">Add Tag</p>
      </template>
      <template v-slot:body>
        <div v-for="(eachTag, index) in getTagList" :key="index">
          <div class="tag-item-list">
            <InputField :disabled="!!eachTag.name" :value="eachTag.name" :id="'tagName'" placeholder="Tag Name" />
            <InputField :value="eachTag.type" :id="'tagType'" placeholder="Tag Type" />
            <InputField :value="eachTag.price" :id="'tagPrice'" placeholder="Tag Price" />
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="button-strip">
          <div class="button-div">
            <ButtonComp :id="'call-button'" :value="'Add Category'" :height="'40px'" :width="'100%'" :color="'#C59B31'"
              @clickEvent="addCategoryTag()" />
          </div>
        </div>
      </template>
    </modal-component>
  </div>
</template>

<script>
import { convertToIdentifier } from "../../../utility";
export default {
  data() {
    return {
      show: false,
      categories: [],
    };
  },
  props: {
    toEditCategory: {
      type: Object
    },
  },
  created() { },
  computed: {
    getTagList() {
      if (
        this.toEditCategory &&
        this.toEditCategory.tagList &&
        this.toEditCategory.tagList.length > 0
      ) {
        return [
          ...this.toEditCategory.tagList,
          { name: "", tag: "", price: "" },
        ];
      }
      return [{ name: "", tag: "", price: "" }];
    },
  },
  methods: {
    async addCategoryTag() {
      const data = [];
      const rows = document.querySelectorAll(".tag-item-list");
      rows.forEach(function (row) {
        const name = row.querySelector("#input-comp-tagName").value;
        const identifier = convertToIdentifier(name);
        const type = row.querySelector("#input-comp-tagType").value;
        const price = row.querySelector("#input-comp-tagPrice").value;

        const rowData = {
          name,
          type,
          identifier,
          price,
        };

        if (name) {
          data.push(rowData);
        }
      });



      await this.postApiCall('/restaurant/addTagsToCategory', { identifier: this.toEditCategory.identifier, tagList: data });
      window.location.reload();
      // Meteor.call(
      //   "addTagsToCategory",
      //   { identifier: this.toEditCategory.identifier, tagList: data },
      //   function (result) {
      //     if (result) {
      //       window.location.reload();
      //     }
      //   }
      // );
    },
    addTagSpace() {
      // this.toEditCategory.tagList = [
      //   ...this.toEditCategory.tagList,
      //   { name: "", tag: "", price: "" },
      // ];
    },
    turnModalOff() {
      this.$emit("turnCategoryTagModalOff");
    },
  },
};
</script>

<style lang="scss" scoped></style>
