<template>
  <div>
    <div class="frame">
      <div class="center">
        <div class="component">
          <div id="minus" @click="editItemCount('minus')">-</div>
          <div id="counter">
            {{ count }}
            <!-- {{ orders[item.identifier] || 0 }} -->
          </div>
          <div id="plus" @click="editItemCount('plus')">+</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {
    multiple: { type: Number, default: 1 },
    count: { type: Number, default: 0 },
  },
  methods: {
    editItemCount(action) {
      if (action === "plus") {
        this.$emit("edited-value", { count: this.count + this.multiple, action: 'PLUS' });
      } else if (action === "minus") {
        this.$emit("edited-value", { count: this.count - this.multiple, action: 'MINUS' });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.frame {
  border-radius: 2px;
  color: #333;
  margin-top: 13px;
  font-family: "Open Sans", Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.center {
  //   display: block;
  width: max-content;
}

.component {
  border: 1px solid black;
  padding: 5px;
  border-radius: 5px;
}

#plus,
#minus,
#counter {
  padding: 10px;
  // font-size: 25px;
  font-weight: bold;
  display: inline;
}
</style>
