<template>
  <div class="super-admin-container">
    <div :style="{ display: 'flex' }">
      <div :style="{ backgroundColor: '#e6ffe6' }">Not yet accepted</div>
      &nbsp;
      <div :style="{ backgroundColor: '#ffbf80' }">Accepted</div>
      &nbsp;
      <div :style="{ backgroundColor: '#ffffe6' }">Preparation Started</div>
      &nbsp;
    </div>
    <div
      v-for="order in orderList"
      :key="order._id"
      class="order-card"
      :style="getCardColor(order)"
    >
      <div class="order-card-header">
        <div>{{ order.restaurantName }}</div>
        <image-button
          :src="'/images/call.png'"
          :width="'20px'"
          alt=""
          :color="'white'"
          @click="callRestaurant(order.restaurantContact)"
        />
      </div>
      <div>₹&nbsp;{{ order.totalPayableToMerchant }}</div>
      <div>Order Id: {{ order.externalOrderId }}</div>
      <div v-for="items in order.orderData" :key="items._id">
        <div class="menu-count">
          <p class="count">{{ `${items.count} x ${items.itemName}` }}</p>
        </div>
      </div>
      <div>
        <div class="button-div">
          <ButtonComp
            :id="'get-order-detail-button'"
            :value="'View Order Detail'"
            :height="'40px'"
            :width="'100%'"
            @clickEvent="getOrderDetail(order._id)"
          />
        </div>
      </div>
      <div>
        {{ getDateTime(orderData?.createdOn) }}
      </div>

      <div class="button-strip">
        <div class="button-div">
          <ButtonComp
            :id="'admin-cancel-button'"
            :textToShow="'Are you sure to Cancel the order ?'"
            :showConfirmationModal="true"
            :value="'Cancel Order'"
            :height="'40px'"
            :width="'100%'"
            @clickEvent="takeAction(order, 'CANCEL_ORDER')"
          />
        </div>
        <div class="button-div">
          <ButtonComp
            :id="'admin-refund'"
            :textToShow="'Are you sure to refund the order ?'"
            :showConfirmationModal="true"
            :value="'Refund Order'"
            :height="'40px'"
            :width="'100%'"
            @clickEvent="takeAction(order, 'REFUND_ORDER')"
          />
        </div>
      </div>
      <div>Porter Actions</div>
      <div class="button-strip">
        <div class="button-div">
          <ButtonComp
            :id="'admin-cancel-delivery'"
            :textToShow="'Are you sure to cancel the delivery ?'"
            :showConfirmationModal="true"
            :value="'Cancel Delivery'"
            :height="'40px'"
            :width="'100%'"
            @clickEvent="takeAction(order, 'CANCEL_DELIVERY')"
          />
        </div>
        <div class="button-div">
          <ButtonComp
            :id="'admin-reassign-delivery'"
            :textToShow="'Are you sure to reassign the delivery ?'"
            :showConfirmationModal="true"
            :value="'Reassign Delivery'"
            :height="'40px'"
            :width="'100%'"
            @clickEvent="takeAction(order, 'REASSIGN_DELIVERY')"
          />
        </div>
      </div>

      <div>Pidge Actions</div>
      <div class="button-strip">
        <div class="button-div">
          <ButtonComp
            :id="'admin-cancel-delivery'"
            :textToShow="'Are you sure to cancel the delivery ?'"
            :showConfirmationModal="true"
            :value="'Cancel Delivery'"
            :height="'40px'"
            :width="'100%'"
            @clickEvent="takeAction(order, 'CANCEL_DELIVERY', 'v2')"
          />
        </div>
        <div class="button-div">
          <ButtonComp
            :id="'admin-reassign-delivery'"
            :textToShow="'Are you sure to reassign the delivery ?'"
            :showConfirmationModal="true"
            :value="'Reassign Delivery'"
            :height="'40px'"
            :width="'100%'"
            @clickEvent="takeAction(order, 'REASSIGN_DELIVERY', 'v2')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";

export default {
  components: {},
  data() {
    return {
      orderList: [],
    };
  },
  async created() {
    this.checkForIncomingOrders();
  },
  methods: {
    getOrderDetail(orderId) {
      this.$router.push(`/get-full-order-detail/${orderId}`);
    },
    async takeAction(order, actionType, version) {
      if (actionType === "CANCEL_ORDER") {
        await this.postApiCall("/superAdmin/adminRejectOrder", {
          incomingOrderId: order._id,
        });
      } else if (actionType === "REFUND_ORDER") {
        await this.postApiCall("/superAdmin/adminRefundOrder", {
          incomingOrderId: order._id,
        });
      } else if (actionType === "CANCEL_DELIVERY" && version === "v2") {
        await this.postApiCall("/superAdmin/v2/adminCancelDelivery", {
          incomingOrderId: order._id,
        });
      } else if (actionType === "REASSIGN_DELIVERY" && version === "v2") {
        await this.postApiCall("/superAdmin/v2/reassignDelivery", {
          incomingOrderId: order._id,
        });
      } else if (actionType === "CANCEL_DELIVERY") {
        await this.postApiCall("/superAdmin/adminCancelDelivery", {
          incomingOrderId: order._id,
        });
      } else if (actionType === "REASSIGN_DELIVERY") {
        await this.postApiCall("/superAdmin/reassignDelivery", {
          billId: order.billId,
        });
      }
      this.infoAlert("Order Updated");
    },
    getCardColor(order) {
      if (order.orderStatus === "ORDER_WAITING") {
        return { backgroundColor: "#e6ffe6", borderColor: "#00e600" };
      } else if (order.orderStatus === "PREPARATION_STARTED") {
        return { backgroundColor: "#ffffe6", borderColor: "yellow" };
      } else if (order.orderStatus === "ACCEPTED") {
        return { backgroundColor: "#ffbf80", borderColor: "#663300" };
      }
    },
    async callRestaurant(phoneNumber) {
      window.open(`tel:${phoneNumber}`);
    },
    async checkForIncomingOrders() {
      this.orderList = await this.getApiCall(
        "/superAdmin/checkForIncomingOrders"
      );
    },
    getDateTime(datetime) {
      return moment(datetime).format("LLL");
    },
  },
};
</script>

<style lang="scss" scoped>
.button-strip {
  display: flex;
  width: 100%;
  justify-content: space-between;

  .button-div {
    margin: 16px 0px 16px 0px;
    width: calc(50% - 15px);
  }

  .check-in-button-div {
    //   margin: 0px 16px 16px 16px;
    //   width: 100%;
    //   justify-content: space-between;
  }
}

.action-butts {
  display: flex;
  width: 30%;
}

.order-card {
  padding: 16px;
  border: 1px solid blue;
  border-radius: 5px;
  margin-bottom: 7px;

  .order-card-header {
    display: flex;
    justify-content: space-between;
  }
}

.super-admin-container {
  margin: 16px 16px 0px 16px;
}
</style>
