<template>
  <div class="modal-container">
    <modal-component :isScrollable="true">
      <template v-slot:header>
        <p @click="turnModalOff()">x</p>
        <p class="modal-header">Add Category</p>
      </template>
      <template v-slot:body>
        <div>
          <InputField id="categoryName" placeholder="Category Name" />
        </div>
      </template>
      <template v-slot:footer>
        <div class="button-strip">
          <div class="button-div">
            <ButtonComp
              :id="'call-button'"
              :value="'Add Category'"
              :height="'40px'"
              :width="'100%'"
              :color="'#C59B31'"
              @clickEvent="addCategory()"
            />
          </div>
        </div>
      </template>
    </modal-component>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      categories: [],
    };
  },
  props: {},
  created() {},
  methods: {
    async addCategory() {
      let categoryName = document.querySelector(
        "#input-comp-categoryName"
      ).value;
      await this.postApiCall("/restaurant/addCategories", {
        name: categoryName,
      });
      window.location.reload();
    },
    turnModalOff() {
      this.$emit("turnCategoryModalOff");
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-container {
  height: 500px;
}

.modal-header {
  // font-size: 40px;
}
</style>
