<template>
  <div class="modal-container">
    <modal-component v-show="show" :isScrollable="true">
      <template v-slot:header>
        <div :style="{ display: 'flex', justifyContent: 'space-between' }">
          <h2 class="modal-header">Policies</h2>
          <p
            @click="
              () => {
                $emit('turnOffModal');
              }
            "
          >
            x
          </p>
        </div>
      </template>
      <template v-slot:body>
        <div>
          <a href="https://app.tumyto.com/policies/contactus.txt" target="_blank" rel="noopener noreferrer">
            <div 
              :style="{ padding: '20px', backgroundColor: '#f2f2f2', borderRadius: '5px', marginDown: '16px' }"
            >
              <p>Contact Us</p>
            </div>
          </a>
        </div>
        <div>
          <a href="https://app.tumyto.com/policies/termsnconditions.txt" target="_blank" rel="noopener noreferrer">
            <div 
              :style="{ padding: '20px', backgroundColor: '#f2f2f2', borderRadius: '5px', marginDown: '16px' }"
            >
              <p>Terms and Conditions</p>
            </div>
          </a>
        </div>
        <div>
          <a href="https://app.tumyto.com/policies/aboutus.txt" target="_blank" rel="noopener noreferrer">
            <div 
              :style="{ padding: '20px', backgroundColor: '#f2f2f2', borderRadius: '5px', marginDown: '16px' }"
            >
              <p>About Us</p>
            </div>
          </a>
        </div>
        <div>
          <a href="https://app.tumyto.com/policies/refund.txt" target="_blank" rel="noopener noreferrer">
            <div 
              :style="{ padding: '20px', backgroundColor: '#f2f2f2', borderRadius: '5px', marginDown: '16px' }"
            >
              <p>Refund and Cancellation Policy</p>
            </div>
          </a>
        </div>
        <div>
          <a href="https://app.tumyto.com/policies/privacy.txt" target="_blank" rel="noopener noreferrer">
            <div 
              :style="{ padding: '20px', backgroundColor: '#f2f2f2', borderRadius: '5px', marginDown: '16px' }"
            >
              <p>Privacy Policy</p>
            </div>
          </a>
        </div>
        <div>
          <a href="https://app.tumyto.com/policies/shipingpolicy.txt" target="_blank" rel="noopener noreferrer">
            <div 
              :style="{ padding: '20px', backgroundColor: '#f2f2f2', borderRadius: '5px', marginDown: '16px' }"
            >
              <p>Shipping Policy</p>
            </div>
          </a>
        </div>
        
      </template>
      <template v-slot:footer>
        <div class="footer-class">
          
        </div>
      </template>
    </modal-component>
  </div>
</template>

<script>
import _ from "underscore";
import { getACustomisationString } from "../../store/order.store";
export default {
  data() {
    return {};
  },
  props: {
    show: { type: Boolean, default: true },
  },
  methods: {
    getCustomisationData(identifier, customization) {
      let menuItems = this.$store.state.menuItem;
      let reqMenuItem = _.find(menuItems, function (item) {
        return item.identifier === identifier;
      });
      let category = reqMenuItem.categoryIdentifier;
      let categoriesSystem = this.$store.state.categoryConfig;
      let reqCategory = _.find(categoriesSystem, function (item) {
        return item.identifier === category;
      });
      let customisationName = [];
      for (
        let i = 0;
        reqCategory && reqCategory.tagList && i < reqCategory.tagList.length;
        i++
      ) {
        let nestedItem = reqCategory && reqCategory.tagList[i];
        let match = _.find(customization, function (obj) {
          return obj.name === nestedItem.identifier;
        });
        if (match) {
          customisationName.push({
            name: nestedItem.name,
            identifier: nestedItem.identifier,
            price: nestedItem.price,
          });
        }
      }
      return customisationName;
    },
    increment() {
      this.counter += 1;
    },
    getItemCount(identifier, customization) {
      let itemMixCount = this.$store.state.itemMixCount;
      return itemMixCount[
        `${identifier},${getACustomisationString(customization)}`
      ];
    },
    getTotalPrice() {
      return this.$store.state.amount;
    },
    editOrder() {
      this.$emit("editOrder");
    },
    confirmOrder() {
      if (parseFloat(this.$store.state.amount)) {
        this.$emit("confirmOrder");
      } else {
        this.errorAlert("Add dishes to Order");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.background-body {
  // background-color: crimson;
}

.modal-container {
  height: 500px;
}

.modal-header {
  // font-size: 40px;
}

.order-confirm-item-list {
  padding: 20px;
  margin-bottom: 10px;
  background-color: #f4f4f4;
  border-radius: 5px;

  .item-name-quantity-price {
    display: flex;
    justify-content: space-between;
  }
}

.display-flex {
  display: flex;
  justify-content: space-between;
}

.footer-class {
  .total-price {
    display: flex;
    justify-content: space-between;

    p {
      // font-size: 25px;
    }
  }

  .button-strip {
    display: flex;
    width: 100%;
    justify-content: space-between;

    .button-div {
      margin: 16px 0px 16px 0px;
      width: calc(50% - 5px);
    }

    .check-in-button-div {
      margin: 0px 16px 16px 16px;
      width: 100%;
      justify-content: space-between;
    }
  }

  .reminder {
    display: flex;
    // font-size: 10px;
    justify-content: center;
    margin-bottom: 10px;
  }
}
</style>
