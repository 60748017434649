<template>
    <div class="super-admin-container">
        <andypf-json-viewer :data="jsonData"></andypf-json-viewer>
    </div>
</template>
  
<script>
import "@andypf/json-viewer";

export default {
    components: {},
    data() {
        return {
            jsonData: {}
        };
    },
    async created() {
        let { incomingOrderId } = this.$route.params;
        this.jsonData = await this.postApiCall('/superAdmin/getFullOrderDetail', {incomingOrderId: incomingOrderId});
        this.jsonData = this.jsonData[0];
    },
    methods: {
        getOrderDetail() {
            
        }
    },
};
</script>
  

<style lang="scss" scoped>

.button-strip {
    display: flex;
    width: 100%;
    justify-content: space-between;

    .button-div {
      margin: 16px 0px 16px 0px;
      width: calc(50% - 15px);
    }

    .check-in-button-div {
    //   margin: 0px 16px 16px 16px;
    //   width: 100%;
    //   justify-content: space-between;
    }
}

.action-butts {
    display: flex;
    width: 30%
}

.order-card {
    padding: 16px;
    border: 1px solid blue;
    border-radius: 5px;
    margin-bottom: 7px;

    .order-card-header {
        display: flex;
        justify-content: space-between;
    }
}

.super-admin-container {
    margin: 16px 16px 0px 16px;
}
</style>