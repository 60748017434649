<template>
    <div class="modal-container">
        <modal-component v-show="show" :isScrollable="true">
            <template v-slot:header>
                <div :style="{ display: 'flex', justifyContent: 'space-between' }">
                    <h1 class="modal-header">Set Filters</h1>
                    <p @click="() => {
                        $emit('turnOffModal');
                    }
                        ">
                        x
                    </p>
                </div>
            </template>
            <template v-slot:body>
                <h2>From</h2>

                <div class="datetime-select">
                    <select v-model="selectedFromYear" @change="updateSelectedDate()">
                        <option v-for="year in years" :key="year" :value="year">{{ year }}</option>
                    </select>
                    <select v-model="selectedFromMonth" @change="updateSelectedDate()">
                        <option v-for="(month, index) in months" :key="month" :value="index + 1">{{ month }}
                        </option>
                    </select>
                    <select v-model="selectedFromDay" @change="updateSelectedDate()">
                        <option v-for="day in days" :key="day" :value="day">{{ day }}</option>
                    </select>
                    <select v-model="selectedFromHour" @change="updateSelectedDate()">
                        <option v-for="hour in hours" :key="hour" :value="hour">{{ hour }}</option>
                    </select>
                    <select v-model="selectedFromMinute" @change="updateSelectedDate()">
                        <option v-for="minute in minutes" :key="minute" :value="minute">{{ minute }}</option>
                    </select>
                </div>
                <div>
                    <p>Start Time : <span>{{ getStartTime() }}</span></p>
                </div>
                <h2>To</h2>
                <div class="datetime-select">
                    <select v-model="selectedToYear" @change="updateSelectedDate()">
                        <option v-for="year in years" :key="year" :value="year">{{ year }}</option>
                    </select>
                    <select v-model="selectedToMonth" @change="updateSelectedDate()">
                        <option v-for="(month, index) in months" :key="month" :value="index + 1">{{ month }}
                        </option>
                    </select>
                    <select v-model="selectedToDay" @change="updateSelectedDate()">
                        <option v-for="day in days" :key="day" :value="day">{{ day }}</option>
                    </select>
                    <select v-model="selectedToHour" @change="updateSelectedDate()">
                        <option v-for="hour in hours" :key="hour" :value="hour">{{ hour }}</option>
                    </select>
                    <select v-model="selectedToMinute" @change="updateSelectedDate()">
                        <option v-for="minute in minutes" :key="minute" :value="minute">{{ minute }}</option>
                    </select>
                </div>
                <div>
                    <p>Stop Time : <span>{{ getStopTime() }}</span></p>
                </div>
            </template>
            <template v-slot:footer>
                <div class="footer-class">
                    <button-comp @clickEvent="() => {
                        this.$emit('fetch-list');
                    }
                        " :value="'Set Filters'" class="set-filter-button" />
                </div>
            </template>
        </modal-component>
    </div>
</template>
  
<script>
import moment from 'moment-timezone';

export default {
    data() {
        return {
            selectedFromYear: '',
            selectedFromMonth: '',
            selectedFromDay: '',
            selectedFromHour: '',
            selectedFromMinute: '',
            selectedToYear: '',
            selectedToMonth: '',
            selectedToDay: '',
            selectedToHour: '',
            selectedToMinute: '',
            show: true,
            years: [],
            months: [
                'January', 'February', 'March', 'April', 'May', 'June',
                'July', 'August', 'September', 'October', 'November', 'December'
            ],
            days: [],
            hours: [],
            minutes: []
        };
    },
    mounted() {
        this.initDatetime();
    },
    methods: {
        getStartTime() {
            let fromDateString = `${this.selectedFromYear}-${this.selectedFromMonth}-${this.selectedFromDay} ${this.selectedFromHour}:${this.selectedFromMinute}`;
            return moment(fromDateString, 'YYYY-MM-DD HH:mm').format('lll');
        },
        getStopTime() {
            let toDateString = `${this.selectedToYear}-${this.selectedToMonth}-${this.selectedToDay} ${this.selectedToHour}:${this.selectedToMinute}`;
            return moment(toDateString, 'YYYY-MM-DD HH:mm').format('lll');
        },
        initDatetime() {
            // Set up the years, months, days, hours, and minutes
            const currentYear = new Date().getFullYear();
            const startYear = currentYear - 10;
            const endYear = currentYear;

            for (let year = startYear; year <= endYear; year++) {
                this.years.push(year);
            }

            for (let day = 1; day <= 31; day++) {
                this.days.push(day);
            }

            for (let hour = 0; hour <= 23; hour++) {
                this.hours.push(hour);
            }

            for (let minute = 0; minute <= 59; minute++) {
                this.minutes.push(minute);
            }

            // Set initial selected values to current date and time
            let yesterday = moment().subtract(1, 'day');
            this.selectedFromYear = yesterday.format('YYYY');
            this.selectedFromMonth = yesterday.format('M'); // Months are zero-based
            this.selectedFromDay = yesterday.format('D');
            this.selectedFromHour = yesterday.format('H');
            this.selectedFromMinute = yesterday.format('mm');

            let todaysDate = moment();

            this.selectedToYear = todaysDate.format('YYYY');
            this.selectedToMonth = todaysDate.format('M') // Months are zero-based
            this.selectedToDay = todaysDate.format('D');
            this.selectedToHour = todaysDate.format('H');
            this.selectedToMinute = todaysDate.format('mm');
        },
        updateSelectedDate() {
            let dateObject = {
                from: {
                    year: this.selectedFromYear,
                    month: this.selectedFromMonth,
                    day: this.selectedFromDay,
                    hour: this.selectedFromHour,
                    minute: this.selectedFromMinute
                },
                to: {
                    year: this.selectedToYear,
                    month: this.selectedToMonth,
                    day: this.selectedToDay,
                    hour: this.selectedToHour,
                    minute: this.selectedToMinute
                }
            };
            this.$emit('selected-range-date', dateObject);
        }
    }
};
</script>
  
<style lang="scss" scoped>
.datetime-select {
    margin: 16px 0px;
    display: flex;
    align-items: center;
}

.datetime-select select {
    margin-right: 10px;
}


.close {
    /* background-image: url("/cancel.png"); */
    background-size: cover;
    height: 25px;
    width: 25px;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
    color: #fff;
}

.modal-mask {
    position: fixed;
    z-index: 5002;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    transition: opacity 0.3s ease;
}

.modal-mask.no-spacing .modal-content {
    padding: 0;
}

.modal-mask.no-spacing .modal-body {
    padding: 0;
    margin: 0;
}

.modal-wrapper {
    display: table-cell;
    height: 100vh;
    vertical-align: middle;
}

.modal-content {
    position: relative;
    height: 30%;
    width: min(90vw, 500px);
    margin: 0px auto;
    padding: 10px;
    background-color: #fff;
    border-radius: 5px;
    border: 2px solid crimson;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;

    display: flex;
    flex-flow: column;
}

.modal-header span {
    // font-size: 20px;
    margin-top: 0;
    flex: 0 1 auto;
}

.modal-body {
    margin: 0px 0px;
    flex: 1 1 auto;
}

.modal-body-scrollable {
    max-height: 100%;
    overflow-y: auto;
}

.modal-footer {
    /* flex: 0 1 calc(100%-210%); */
}

.modal-default-button {
    float: right;
}

/*
  * The following styles are auto-applied to elements with
  * transition="modal" when their visibility is toggled
  * by Vue.js.
  *
  * You can easily play with the modal transition by editing
  * these styles.
  */

.modal-enter {
    opacity: 0;
}

.modal-leave-active {
    opacity: 0;
}

.modal-enter .modal-content,
.modal-leave-active .modal-content {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

.modal-content.full-screen-modal-content {
    max-width: none;
    width: 90%;
}
</style>
  