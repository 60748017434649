<template>
  <div class="modal-container">
    <modal-component v-show="show" :isScrollable="true">
      <template v-slot:header>
        <div :style="{display: 'flex', justifyContent: 'space-between'}">
          <h2 class="modal-header">Add Choices</h2>
          <p @click="turnModalOff()">x</p>
        </div>
      </template>
      <template v-slot:body>
        <div>
          <form>
            <div v-for="tag in getCustomizationList()" :key="tag.identifier">
              <div class="tag-layout">
                <div class="tag-horizontal-layout">
                  <input :id="tag.identifier" :type="'checkbox'" :value="`${tag.identifier}||${tag.price}||${tag.name}`"
                    :name="tag.type" class="tag-check-list" @click="goToPreviousStateOfInput(tag.identifier, tag.type)" />
                  <p>{{ tag.name }}</p>
                </div>
                <p v-if="tag.price" class="tag-price">{{ `₹ ${tag.price}` }}</p>
              </div>
            </div>
          </form>
        </div>
      </template>
      <template v-slot:footer>
        <div class="button-strip footer-class">
          <div class="button-div">
            <ButtonComp :id="'call-button'" :value="'Add Item'" :height="'40px'" :width="'100%'" :color="'#C59B31'"
              @clickEvent="confirmTags()" />
          </div>
          <!-- <div class="button-div">
            <ButtonComp
              :id="'direction-button'"
              :value="'Confirm Order'"
              :height="'40px'"
              :width="'100%'"
              :color="'#7FBD4F'"
              @click="confirmOrder()"
            />
          </div> -->
        </div>
      </template>
    </modal-component>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    show: { type: Boolean, default: true },
    categoryForChosenItem: { type: String, default: "" },
  },
  created() {
    this.getCustomizationList();
  },
  methods: {
    confirmTags() {
      let tagsList = [];
      document.querySelectorAll(".tag-check-list")
        .forEach((i) => {
          if (i.checked) {
            let data = i.value.split("||");
            tagsList.push({ name: data[0], price: data[1], localisedName: data[2] });
          }
        });
      this.$emit(
        "addCustomTags",
        undefined,
        undefined,
        undefined,
        tagsList,
        undefined
      );
    },
    goToPreviousStateOfInput(id, name) {
      let currentValue = document.getElementById(id).checked;
      if (currentValue) {
        document.querySelectorAll(`input[type="checkbox"][name="${name}"]`).forEach(function (item) {
          item.checked = false;
        });
        document.getElementById(id).checked = true;
      } else {
        // document.querySelectorAll(`input[type="checkbox"][name="${name}"]`).each(function () {
        //   document.querySelectorAll(`input[type="checkbox"]`).prop("checked", false);
        // });
      }
    },
    getCustomizationList() {
      let cateogryList = this.$store.state.categoryConfig;
      let config = {};
      cateogryList.map((item) => {
        if (item.identifier === this.categoryForChosenItem) {
          config = item.tagList;
        }
      });
      return config;
    },
    turnModalOff() {
      this.$emit("turnCustomOrderModalOff");
    },
    editOrder() {
      this.$emit("editOrder");
    },
    confirmOrder() {
      this.$emit("confirmOrder");
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-container {
  height: 500px;
}

.modal-header {
  // font-size: 40px;
}

.tag-layout {
  display: flex;
  justify-content: space-between;
  margin: 16px 16px 16px 0px;

  .tag-horizontal-layout {
    display: flex;

    input {
      border: 2px solid crimson;
      margin: 0px 5px 0px 0px;
    }

    // label::before {
    //   padding: 16px 16px 16px 16px;
    // }
  }

  .tag-price {
    // margin: 16px 0px 0px 0px;
  }
}

.order-confirm-item-list {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  margin-bottom: 10px;
  background-color: #f4f4f4;
  border-radius: 5px;
}

.footer-class {
  .total-price {
    display: flex;
    justify-content: space-between;

    p {
      // font-size: 25px;
    }
  }

  .button-strip {
    width: 100%;
    display: flex;
    // font-size: 10px;

    .button-div {
      margin: 16px 0px 16px 0px;
      width: 100%;
      justify-content: space-between;
    }

    .check-in-button-div {
      margin: 0px 16px 16px 16px;
      width: 100%;
      justify-content: space-between;
    }
  }
}
</style>
