<template>
  <!-- <button class="toggle-button" :class="{ active: value }" @click="toggle">
    {{ value ? 'GO ONLINE' : 'GO OFFLINE' }}
  </button> -->

  <ButtonComp
    class="toggle-button"
    :id="'bill-collect-button'"
    :value="dynamicButtonValue().buttonText"
    :height="'40px'"
    :width="'100%'"
    :color="dynamicButtonValue().buttonColor"
    :class="{ active: value }"
    :textToShow="dynamicButtonValue().confirmationText"
    :showConfirmationModal="true"
    @clickEvent="toggle"
  />
</template>

<script>
export default {
  data() {
    return {
      value: false,
    };
  },
  async created() {
    let result = await this.getApiCall("/restaurant/getRestaurantStatus");
    if (result === "ONLINE") {
      this.value = false;
    } else {
      this.value = true;
    }
  },
  methods: {
    async toggle() {
      this.value = !this.value;
      await this.postApiCall("/restaurant/setRestaurantStatus", {
        status: this.value ? "OFFLINE" : "ONLINE",
      });
    },
    dynamicButtonValue() {
      if (this.value) {
        return {
          buttonText: "GO-ONLINE",
          confirmationText: "Are you sure you want to go Online",
          buttonColor: "green",
        };
      } else {
        return {
          buttonText: "GO-OFFLINE",
          confirmationText: "Are you sure you want to go Offline",
          buttonColor: "red",
        };
      }
    },
  },
};
</script>

<style>
.toggle-button {
  padding: 8px 16px;
  background-color: crimson;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.toggle-button.active {
  background-color: #00cc00;
}
</style>
