<template>
  <div class="modal-container">
    <modal-component v-show="show" :isScrollable="true">
      <template v-slot:header>
        <h2 class="modal-header">Order Review</h2>
      </template>
      <template v-slot:body>
        <div v-for="item in chosenItems" :key="item.identifier" class="order-confirm-item-list">
          <div class="item-name-quantity-price">
            <p>{{ item.itemName }}</p>
            <p v-if="!item.discountPrice">
              {{
                `${getItemCount(item.itemIdentifier, item.customization)} x ₹ ${item.itemPrice
                }`
              }}
            </p>
            <p v-else>
              {{
                `${getItemCount(item.itemIdentifier, item.customization)} x ₹ ${item.discountPrice}`
              }} <s>(₹ {{ item.itemPrice }})</s>
            </p>
          </div>
          <div>
            <div v-for="customisation in getCustomisationData(
              item.itemIdentifier,
              item.customization
            )" :key="customisation" class="display-flex">
              <p>{{ customisation.name }}</p>
              <p v-if="customisation.price">{{ `₹ ${customisation.price}` }}</p>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="footer-class">
          <div class="total-price">
            <p>Total**</p>
            <p>₹{{ getTotalPrice() }}</p>
          </div>
          <div class="button-strip">
            <div class="button-div">
              <ButtonComp :id="'call-button'" :value="'Edit Order'" :height="'40px'" :width="'100%'" :color="'#C59B31'"
                @clickEvent="editOrder()" />
            </div>
            <div class="button-div">
              <ButtonComp :id="'direction-button'" :value="'Confirm Order'" :height="'40px'" :width="'100%'"
                :color="'#7FBD4F'" @clickEvent="confirmOrder()" />
            </div>
          </div>
          <div class="reminder">
            <p>** You cannot cancel the order once placed</p>
          </div>
        </div>
      </template>
    </modal-component>
  </div>
</template>

<script>
import _ from "underscore";
import { getACustomisationString } from "../../store/order.store";
export default {
  data() {
    return {};
  },
  props: {
    show: { type: Boolean, default: true },
  },
  computed: {
    chosenItems() {
      return this.$store.state.processedOrderList;
    },
  },
  methods: {
    getCustomisationData(identifier, customization) {
      let menuItems = this.$store.state.menuItem;
      let reqMenuItem = _.find(menuItems, function (item) {
        return item.identifier === identifier;
      });
      let category = reqMenuItem.categoryIdentifier;
      let categoriesSystem = this.$store.state.categoryConfig;
      let reqCategory = _.find(categoriesSystem, function (item) {
        return item.identifier === category;
      });
      let customisationName = [];
      for (
        let i = 0;
        reqCategory && reqCategory.tagList && i < reqCategory.tagList.length;
        i++
      ) {
        let nestedItem = reqCategory && reqCategory.tagList[i];
        let match = _.find(customization, function (obj) {
          return obj.name === nestedItem.identifier;
        });
        if (match) {
          customisationName.push({
            name: nestedItem.name,
            identifier: nestedItem.identifier,
            price: nestedItem.price,
          });
        }
      }
      return customisationName;
    },
    increment() {
      this.counter += 1;
    },
    getItemCount(identifier, customization) {
      let itemMixCount = this.$store.state.itemMixCount;
      return itemMixCount[
        `${identifier},${getACustomisationString(customization)}`
      ];
    },
    getTotalPrice() {
      return this.$store.state.amount;
    },
    editOrder() {
      this.$emit("editOrder");
    },
    confirmOrder() {
      if (parseFloat(this.$store.state.amount)) {
        this.$emit("confirmOrder");
      } else {
        this.errorAlert("Add dishes to Order");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.background-body {
  // background-color: crimson;
}

.modal-container {
  height: 500px;
}

.modal-header {
  // font-size: 40px;
}

.order-confirm-item-list {
  padding: 20px;
  margin-bottom: 10px;
  background-color: #f4f4f4;
  border-radius: 5px;

  .item-name-quantity-price {
    display: flex;
    justify-content: space-between;
  }
}

.display-flex {
  display: flex;
  justify-content: space-between;
}

.footer-class {
  .total-price {
    display: flex;
    justify-content: space-between;

    p {
      // font-size: 25px;
    }
  }

  .button-strip {
    display: flex;
    width: 100%;
    justify-content: space-between;

    .button-div {
      margin: 16px 0px 16px 0px;
      width: calc(50% - 5px);
    }

    .check-in-button-div {
      margin: 0px 16px 16px 16px;
      width: 100%;
      justify-content: space-between;
    }
  }

  .reminder {
    display: flex;
    // font-size: 10px;
    justify-content: center;
    margin-bottom: 10px;
  }
}
</style>
