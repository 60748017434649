<template>
  <div>
    <login />
  </div>
</template>

<script>
import Login from "../Login/Login.vue";
// import * as OneSignal from "onesignal-node";

export default {
  data() {
    return {
      counter: 0,
    };
  },
  components: { Login },
  computed: {},
  async created() {
    const userData = JSON.parse(await localStorage.getItem("userData"));
    let accessRights = userData?.accessRights;

    if (userData) {
      if (!userData.authToken || !userData.username) {
        this.$router.replace(`/home`);
      } else if (
        accessRights?.includes("RECIEVE_ORDER") ||
        accessRights?.includes("CHANGE_ORDER_STATUS")
      ) {
        this.$router.replace(`/accept-delivery-order/INCOMING_ORDER`);
      } else {
        this.$router.replace(`/home`);
      }
    } else {
      this.$router.replace(`/home`);
    }
  },
  methods: {
    increment() {
      this.counter += 1;
    },
  },
};
</script>

<style scoped>
p {
  font-family: serif;
}
</style>
