<template>
  <div class="add-restaurant-container">
    <div class="header">
      <div>
        <p
          class="heading-text"
          :style="{ fontWeight: 'bold', fontSize: '30px' }"
        >
          Add Restaurant Details
        </p>
      </div>
    </div>
    <div>
      <InputField
        id="restraunt-name"
        placeholder="Restraunt Name"
        @change-handler="
          ($event) => {
            name = $event;
          }
        "
      />
      <InputField
        id="restraunt-address"
        placeholder="Restraunt Address"
        @change-handler="
          ($event) => {
            address = $event;
          }
        "
      />
      <InputField
        id="restraunt-avg-price"
        placeholder="Average Price"
        @change-handler="
          ($event) => {
            avgPrice = $event;
          }
        "
      />
      <InputField
        id="restraunt-cuisine"
        placeholder="Cuisine Type"
        @change-handler="
          ($event) => {
            cuisineType = $event;
          }
        "
      />
      <InputField
        id="restraunt-timing"
        placeholder="Timing"
        @change-handler="
          ($event) => {
            timing = $event;
          }
        "
      />
      <InputField
        id="restraunt-timing"
        placeholder="FSSAI Number"
        @change-handler="
          ($event) => {
            fssai = $event;
          }
        "
      />
      <InputField
        id="restraunt-timing"
        placeholder="GST Number"
        @change-handler="
          ($event) => {
            gst = $event;
          }
        "
      />
      <InputField
        id="restraunt-latitude"
        placeholder="Latitude"
        @change-handler="
          ($event) => {
            lat = $event;
          }
        "
      />
      <InputField
        id="restraunt-longitude"
        placeholder="Longitude"
        @change-handler="
          ($event) => {
            lng = $event;
          }
        "
      />
      <div>
        <div
          v-for="(dish, index) in dishList"
          :key="index"
          @click="setDishList(dish)"
        >
          <input
            type="checkbox"
            id="toggle"
            :checked="isDishChecked(accessRight)"
          />
          {{ dish.name }}
        </div>
      </div>
      <InputField
        id="restraunt-contactNumber"
        placeholder="Contact Number"
        @change-handler="
          ($event) => {
            contactNumber = $event;
          }
        "
      />
      <InputField
        id="restraunt-image"
        placeholder="Image"
        @change-handler="
          ($event) => {
            image = $event;
          }
        "
      />
      <InputField
        id="restraunt-starthour"
        placeholder="Start Hour"
        @change-handler="
          ($event) => {
            startHour = $event;
          }
        "
      />
      <InputField
        id="restraunt-endhour"
        placeholder="End Hour"
        @change-handler="
          ($event) => {
            endHour = $event;
          }
        "
      />
      <InputField
        id="restraunt-buildingaddress"
        placeholder="Building Address"
        @change-handler="
          ($event) => {
            buildingAddress = $event;
          }
        "
      />
      <InputField
        id="restraunt-streetAddress"
        placeholder="Street Address"
        @change-handler="
          ($event) => {
            streetAddress = $event;
          }
        "
      />
      <InputField
        id="restraunt-landmark"
        placeholder="Landmark"
        @change-handler="
          ($event) => {
            landmark = $event;
          }
        "
      />
      <InputField
        id="restraunt-pinCode"
        placeholder="Pincode"
        @change-handler="
          ($event) => {
            pinCode = $event;
          }
        "
      />
      <InputField
        id="restraunt-accountId"
        placeholder="Account Number"
        @change-handler="
          ($event) => {
            accountId = $event;
          }
        "
      />
      <InputField
        id="restraunt-IFSC"
        placeholder="IFSC"
        @change-handler="
          ($event) => {
            ifsc = $event;
          }
        "
      />
      <ButtonComp
        :id="'add-res-butt'"
        :height="'40px'"
        :width="'100%'"
        :color="'#C59B31'"
        :value="'Add Restaurant'"
        @clickEvent="addRestraunt()"
      />
    </div>
  </div>
</template>

<script>
import { restaurantTagList } from "../../imports/config.js";
// import { infoAlert } from "../../utility";
export default {
  name: "AddRestraunt",
  data() {
    return {
      counter: 0,
      name: "",
      address: "",
      avgPrice: "",
      cuisineType: "",
      timing: "",
      fssai: "",
      dishList: restaurantTagList,
      gst: "",
      lat: "",
      lng: "",
      tags: "",
      contactNumber: "",
      image: "",
      startHour: "",
      endHour: "",
      buildingAddress: "",
      streetAddress: "",
      landmark: "",
      pinCode: "",
      accountId: "",
      ifsc: "",
    };
  },
  async created() {
    // let restaurantData = await this.getApiCall('/admin/getRestaurantDetails');
  },
  methods: {
    setDishList(item) {
      let currentList = this.tags === "" ? [] : this.tags.split(",");
      currentList.push(item.tag);
      this.tags = currentList.join(",");
    },
    isDishChecked() {},
    async addRestraunt() {
      let result = await this.postApiCall("/admin/addRestaurant", {
        name: this.name,
        address: this.address,
        avgPrice: this.avgPrice,
        cuisineType: this.cuisineType,
        timing: this.timing,
        fssai: this.fssai,
        gst: this.gst,
        lat: this.lat,
        lng: this.lng,
        tags: this.tags,
        contactNumber: this.contactNumber,
        image: this.image,
        startHour: this.startHour,
        endHour: this.endHour,
        buildingAddress: this.buildingAddress,
        streetAddress: this.streetAddress,
        landmark: this.landmark,
        pinCode: this.pinCode,
        accountId: this.accountId,
        ifsc: this.ifsc,
      });
      if (result === "RESTAURANT_CREATED") {
        this.infoAlert("Restaurant Created");
        this.$router.push("/login");
      } else {
        this.errorAlert("Please check your inputs");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.add-restaurant-container {
  margin: 16px 16px 16px 16px;

  .header {
    .heading-text {
      size: 70px;
      font-weight: 600 !important;
    }
  }
}

p {
  font-family: serif;
}
</style>
