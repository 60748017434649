<template>
  <transition name="modal">
    <div :class="noSpacing ? 'no-spacing modal-mask' : 'modal-mask'">
      <div class="modal-wrapper">
        <div class="modal-content">
          <div v-if="!hideHeader" class="modal-header-footer">
            <slot name="header"></slot>
            <span class="close" data-dismiss="modal" @click="close()"
              >&times;</span
            >
          </div>
          <div class="modal-body">
            <slot name="body"></slot>
          </div>
          <div class="modal-header-footer modal-footer">
            <slot name="footer"></slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  name: "ModalComponent",
  props: {
    hideHeader: {
      type: Boolean,
    },
    isFullScreen: {
      type: Boolean,
    },
    noSpacing: {
      type: Boolean,
    },
    isScrollable: {
      type: Boolean,
    },
  },
  data() {
    return {
      fullscreen: this.isFullScreen,
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    getClass() {
      return this.fullscreen
        ? "modal-content full-screen-modal-content"
        : "modal-content";
    },
  },
};
</script>
<style type="scss" scoped>
.modal-header-footer {
  flex: 0 0 auto;
}
.close {
  background-size: cover;
  height: 25px;
  width: 25px;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  color: #fff;
}
.modal-mask {
  position: fixed;
  z-index: 5001;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}
.modal-mask.no-spacing .modal-content {
  padding: 0;
}
.modal-mask.no-spacing .modal-body {
  padding: 0;
  margin: 0;
}

.modal-wrapper {
  display: table-cell;
  height: 100%;
  vertical-align: middle;
}

.modal-content {
  /* position: relative; */
  width: min(90vw, 500px);
  margin: 0px auto;
  padding: 10px;
  background-color: #fff;
  border-radius: 5px;
  border: 2px solid crimson;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 100px);
}

.modal-body {
  flex: 1 1 auto;
  overflow: auto;
  margin: 0px 0px;
  margin-bottom: auto;
}

.modal-footer {
  justify-self: flex-end;
}

.modal-default-button {
  float: right;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-content,
.modal-leave-active .modal-content {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.modal-content.full-screen-modal-content {
  max-width: none;
  width: 90%;
}
</style>
