<template>
    <div>
        <div class="rating-stars">
            <img class="star-class" v-for="(item, index) in stars" :key="index"
                :src="item.filled === true ? '/images/star-glow.png' : '/images/star.png'" @click="setRating(index)" />
        </div>
        <div>
            <textarea v-model="review" class="rating-textarea" placeholder="Write your review" rows="4"></textarea>
        </div>
        <button-comp v-if="hideSubmitButton" :id="'submitRatingButton'" class="submitRatingButton" :value="'Submit Review'" :height="'40px'"
            :width="'100%'" :color="'crimson'" @clickEvent="submitRatingButton" />
    </div>
</template>
  
<script>
export default {
    data() {
        return {
            stars: [
                { filled: false },
                { filled: false },
                { filled: false },
                { filled: false },
                { filled: false }
            ],
            rating: 0,
            review: ""
        };
    },
    props: {
        reviewDetail: {
            type: Object
        },
        hideSubmitButton: {
            type: Boolean
        }
    },
    created() {

    },
    watch: {
        reviewDetail() {
            if (this.reviewDetail?.rating) {
                this.rating = this.reviewDetail?.rating;
                this.setRating(this.rating - 1);
            }
            if (this.reviewDetail?.review) {
                this.review = this.reviewDetail?.review;
            }
        }
    },
    methods: {
        async submitRatingButton() {
            this.$emit('submitRatingButton', this.rating, this.review);
        },
        setRating(index) {
            this.rating = index + 1;
            for (let i = 0; i <= 4; i++) {
                this.stars[i].filled = false;
            }
            for (let i = 0; i <= index; i++) {
                this.stars[i].filled = true;
            }

        },
        toggleStar(index) {
            for (let i = 0; i <= index; i++) {
                this.stars[i].filled = true;
            }
            for (let i = index + 1; i < this.stars.length; i++) {
                this.stars[i].filled = false;
            }
        }
    }
};
</script>
  
<style lang="scss" scoped>
.submitRatingButton {
    margin-bottom: 10px;
}

.rating-stars {
    display: flex;
    margin-bottom: 10px;
}

.star-class {
    width: 25px;
    margin-right: 7px;
}

.rating-textarea {
    width: 100%
}

.star {
    width: 24px
}
</style>