<template>
  <div class="modal-container">
    <modal-component v-show="show" :isScrollable="true">
      <template v-slot:header>
        <div class="edit-order-header">
          <h2 class="modal-header">Edit Order</h2>
          <p @click="turnModalOff()">x</p>
        </div>
      </template>
      <template v-slot:body>
        <div v-for="item in chosenItems" :key="item.identifier" class="order-confirm-item-list">
          <div class="item-name-quantity-price">
            <p>{{ item.itemName }}</p>
            <p>
              {{
                `${getItemCount(item.itemIdentifier, item.customization)} x ₹ ${item.itemPrice
                  }`
              }}
            </p>
          </div>
          <div>
            <div v-for="customisation in getCustomisationData(
              item.itemIdentifier,
              item.customization
            )" :key="customisation.name" class="display-flex">
              <p>{{ customisation.name }}</p>
              <p>{{ `₹ ${customisation.price}` }}</p>
            </div>
          </div>
          <div>
            <ButtonComp :id="'direction-button'" :value="'Remove'" :height="'40px'" :width="'100%'" :color="'#7FBD4F'"
              @clickEvent="removeItem(item.itemIdentifier, item.customization, item)" />
          </div>
        </div>
      </template>
      <template v-slot:footer></template>
    </modal-component>
  </div>
</template>

<script>
import _ from "underscore";
import { getACustomisationString } from "../../store/order.store";
export default {
  data() {
    return {
    };
  },
  props: {
    show: { type: Boolean, default: true },
    itemIdentifier: { type: String, required: true }
  },
  computed: {
    chosenItems() {
      let orderList = this.$store.state.processedOrderList;
      let orderListToShow = [];
      orderList.map(item => {
        if (item.itemIdentifier === this.itemIdentifier) {
          orderListToShow.push(item);
        }
      });
      return orderListToShow;
    }
  },
  methods: {
    turnModalOff() {
      this.$emit("turnShowOrderRemoveModalOff");
    },
    getCustomisationData(identifier, customization) {
      let menuItems = this.$store.state.menuItem;
      let reqMenuItem = _.find(menuItems, function (item) {
        return item.identifier === identifier;
      });
      let category = reqMenuItem.categoryIdentifier;
      let categoriesSystem = this.$store.state.categoryConfig;
      let reqCategory = _.find(categoriesSystem, function (item) {
        return item.identifier === category;
      });
      let customisationName = [];
      for (
        let i = 0;
        i < reqCategory?.tagList.length;
        i++
      ) {
        let nestedItem = reqCategory.tagList[i];
        let match = _.find(customization, function (obj) {
          return obj.name === nestedItem.identifier;
          // return true;
        });
        if (match) {
          customisationName.push({
            name: nestedItem.name,
            identifier: nestedItem.identifier,
            price: nestedItem.price
          });
        }
      }
      return customisationName;
    },
    increment() {
      this.counter += 1;
    },
    getItemCount(identifier, customization) {
      let itemMixCount = this.$store.state.itemMixCount;
      return (
        itemMixCount[
        `${identifier},${getACustomisationString(customization)}`
        ] || 0
      );
    },
    getTotalPrice() {
      return this.$store.state.amount;
    },
    editOrder() {
      this.$emit("editOrder");
    },
    confirmOrder() {
      this.$emit("confirmOrder");
    },
    removeItem(itemIdentifier, customization) {
      this.$emit("removeItem", itemIdentifier, customization);
    }
  }
};
</script>

<style lang="scss" scoped>
.edit-order-header {
  display: flex;
  justify-content: space-between;
}

.modal-container {
  height: 500px;
}

.modal-header {
  // font-size: 40px;
}

.order-confirm-item-list {
  padding: 20px;
  margin-bottom: 10px;
  background-color: #f4f4f4;
  border-radius: 5px;

  .item-name-quantity-price {
    display: flex;
    justify-content: space-between;
  }

  .display-flex {
    display: flex;
    justify-content: space-between;
  }
}

.footer-class {
  .total-price {
    display: flex;
    justify-content: space-between;

    p {
      // font-size: 25px;
    }
  }

  .button-strip {
    width: 100%;
    display: flex;
    // width: 200px;

    .button-div {
      margin: 16px 0px 16px 0px;
      width: 100%;
      justify-content: space-between;
    }

    .check-in-button-div {
      margin: 0px 16px 16px 16px;
      width: 100%;
      justify-content: space-between;
    }
  }
}
</style>
