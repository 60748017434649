<template>
    <div>
        <div>
            <h1>Terms and Conditions</h1>
            <p>Please read these terms and conditions ("Terms") carefully before using our application ("the Application").
                By using the Application, you agree to be bound by these Terms. If you do not agree with any part of these
                Terms, please do not use the Application.<br /><br />

                1. Orders:
                a. Once an order is placed through the Application, it cannot be canceled or modified. Please review your
                order carefully before confirming.<br />
                b. We reserve the right to refuse or cancel any order at our sole discretion, without providing any
                reason.<br /><br />

                2. Privacy and Data Sharing:
                a. By using the Application, you acknowledge and agree that your phone number may be shared with vendors
                registered on the platform.
                b. We are committed to protecting your privacy and handling your personal information in accordance with
                applicable laws and our Privacy Policy.<br /><br />

                3. Intellectual Property:
                a. All content, materials, and intellectual property displayed or made available through the Application are
                protected by copyright, trademark, and other applicable intellectual property laws.
                b. You may not copy, modify, distribute, transmit, display, perform, reproduce, publish, license, create
                derivative works from, transfer, or sell any content, materials, or intellectual property obtained through
                the Application without prior written permission from the respective rights holders.<br /><br />

                4. Limitation of Liability:
                a. The Application and its content are provided on an "as is" and "as available" basis. We do not warrant
                that the Application will be uninterrupted, error-free, or free from viruses or other harmful components.
                b. In no event shall we be liable for any direct, indirect, incidental, consequential, or special damages
                arising out of or in connection with the use or inability to use the Application.<br /><br />

                5. Governing Law:
                These Terms shall be governed by and construed in accordance with the laws of Kolkata. Any
                disputes arising out of or relating to these Terms or the use of the Application shall be subject to the
                exclusive jurisdiction of the courts in Kolkata.<br /><br />

                6. Modifications:
                We reserve the right to modify or amend these Terms at any time. Any changes to the Terms will be effective
                immediately upon posting. Your continued use of the Application after any modifications will constitute your
                acceptance of the modified Terms.</p>
        </div>
        <div>
            <h1>Privacy Policy</h1>
            <p>At Tumyto, we value the privacy and security of our users. This Privacy Policy outlines how we collect, use,
                and protect your personal information when you use our food delivery service, Tumyto. Please read this
                policy carefully to understand our practices regarding your personal data and how we handle it.<br /><br />

                1. Information We Collect:<br /><br />

                1.1 Personal Information: When you use Tumyto, we may collect personal information such as your username,
                phone number, delivery address, and payment details. This information is necessary for providing our food
                delivery services to you.<br /><br />

                1.2 Location Information: With your permission, we may collect and process information about your precise
                location to facilitate accurate delivery and improve our services.<br /><br />

                1.3 Usage Information: We collect information about how you interact with our service, including your device
                information, IP address, browsing activity, and app usage. This data helps us improve our services, analyze
                trends, and enhance your user experience.<br /><br />

                2. Use of Information:<br /><br />

                2.1 Service Delivery: We use the personal information you provide to fulfill your food delivery orders,
                process payments, and communicate with you regarding your orders or any service-related updates.<br /><br />

                2.2 User Experience Improvement: We may use the collected information to enhance and personalize your
                experience on Tumyto, such as suggesting relevant restaurants, optimizing delivery routes, and tailoring
                promotions based on your preferences.<br /><br />

                2.3 Marketing and Communications: With your consent, we may use your contact details to send you promotional
                offers, updates, and newsletters. You can opt-out of receiving such communications at any time.<br /><br />

                2.4 Legal Compliance: We may use your information to comply with applicable laws, regulations, or legal
                processes, respond to legal requests, or protect our rights, privacy, safety, or property.<br /><br />

                3. Data Sharing and Disclosure:<br /><br />

                3.1 Service Providers: We may share your personal information with trusted third-party service providers who
                assist us in delivering our services, such as payment processors, delivery partners, and customer support
                providers. These providers are obligated to handle your data securely and solely for the purposes specified
                by us.<br /><br />

                3.2 Business Transfers: In the event of a merger, acquisition, or sale of our company or assets, your
                personal information may be transferred to the acquiring entity or successor.<br /><br />

                3.3 Legal Requirements: We may disclose your personal information if required by law or in response to valid
                legal requests, such as subpoenas, court orders, or government regulations.<br /><br />

                4. Data Security:<br /><br />

                We implement appropriate technical and organizational measures to protect your personal information from
                unauthorized access, disclosure, alteration, or destruction. However, please note that no data transmission
                or storage method can guarantee absolute security.<br /><br />

                5. Data Retention:<br /><br />

                We retain your personal information only for as long as necessary to fulfill the purposes outlined in this
                Privacy Policy, unless a longer retention period is required or permitted by law.<br /><br />

                6. Your Rights:<br /><br />

                You have the right to access, correct, update, or delete your personal information held by Tumyto. You may
                also have the right to restrict or object to certain data processing activities. To exercise these rights,
                please contact us using the information provided below.<br /><br />

                7. Children's Privacy:<br /><br />

                Tumyto is not intended for use by individuals under the age of 18. We do not knowingly collect personal
                information from children. If you believe we have inadvertently collected information from a child, please
                contact us, and we will promptly delete it.<br /><br />

                8. Changes to this Privacy Policy:<br /><br />

                We may update this Privacy Policy from time to time to reflect changes in our practices</p><br /><br />
        </div>
        <div>
            <h1>Cancellation Policy</h1>
            <p>Please read the below stated points for any cancellations.<br /><br />

                Orders placed can be cancelled only if the preparation dosen't start. Cancellation of orders can only be
                directed over a call to customer support.</p><br /><br />
        </div>
        <div>
            <h1>Refund Policy</h1><br /><br />
            <p>The acceptance of order is subject to availability of products, delivery areas and time. The order may or may
                not be accepted. The order might be cancelled after assessing the circumstances and communicated to
                customer. In such case amount paid by customer is fully refunded to customer's bank account.<br /><br />

                If recipient is not available at the time of delivery, the order will be cancelled. No refund will be made
                in such case.<br /><br />

                Refunds are processed to your payment method within 7 working days.</p>
        </div>
        <div>
            <h1>Shiping Policy</h1>
            <p>1. Delivery Service:<br /><br />
                a. Our food delivery app ("the App") provides delivery services for the food items listed on our platform.
                b. We partner with various delivery service providers to ensure timely and efficient delivery to our
                customers.<br /><br />

                2. Delivery Area:<br /><br />
                a. We have designated delivery areas where we offer our services. Please check the App or contact our
                customer support for information on the available delivery locations.<br /><br />
                b. The delivery area may be subject to change, and we reserve the right to modify or expand our delivery
                coverage at any time.<br /><br />

                3. Delivery Time:<br /><br />
                a. We strive to provide accurate delivery estimates for each order placed through the App. However, please
                note that delivery times may vary depending on factors such as distance, traffic conditions, and order
                volume.<br /><br />
                b. We will make reasonable efforts to deliver your order within the estimated time frame, but we cannot
                guarantee precise delivery times.<br /><br />

                4. Delivery Fees:<br /><br />
                a. Delivery fees may apply to orders placed through the App. The applicable delivery fee will be displayed
                during the checkout process.<br /><br />
                b. The delivery fee may vary based on factors such as the delivery distance, order value, or ongoing
                promotions. Please refer to the App for the specific delivery fee for your order.<br /><br />

                5. Order Tracking:<br /><br />
                a. We provide order tracking functionality through the App, allowing you to monitor the progress of your
                delivery.<br /><br />
                b. Once your order is confirmed, you will receive a tracking ID or a link to track your order's
                status.<br /><br />

                6. Packaging and Quality:<br /><br />
                a. We take utmost care in packaging and ensuring the quality of the food items for delivery. Our packaging
                is designed to maintain the freshness and integrity of the food during transit.<br /><br />
                b. If you encounter any issues with the packaging or quality of the delivered food, please contact our
                customer support within a reasonable time frame for assistance.<br /><br />

                7. Delivery Issues:<br /><br />
                a. In the event of any delivery issues, such as delays, incorrect delivery, or missing items, please contact
                our customer support as soon as possible.<br /><br />
                b. We will work diligently to resolve any delivery-related issues and provide appropriate solutions, which
                may include refunding or replacing the affected items.<br /><br />

                8. Change or Cancellation of Orders:<br /><br />
                a. Once an order is confirmed and payment is processed, changes or cancellations may not be possible. Please
                review your order carefully before submitting.<br /><br />
                b. If you need to modify or cancel your order, please contact our customer support immediately. We will
                assist you to the best of our ability, but we cannot guarantee changes or cancellations after the order is
                placed.<br /><br />

                9. Legal Restrictions:<br /><br />
                a. We comply with all applicable laws and regulations regarding the delivery of food items. Certain items or
                delivery services may be restricted or unavailable based on local regulations.<br /><br />
                b. We reserve the right to refuse delivery or impose additional restrictions as required by law.<br /><br />

                10. Modifications:<br /><br />
                We reserve the right to modify or amend this Shipping Policy at any time. Any changes to the policy will be
                effective immediately upon posting on the App.<br /><br />
            </p>
        </div>
        <div>
            <h1>About Us</h1>
            <p>At Tumyto, we are passionate about providing our customers with a thoughtful and delightful culinary
                experience through our food delivery services. Our mission is to satisfy your cravings and bring the joy of
                classic taste right to your doorstep.<br /><br />

                Honesty is at the core of everything we do. We believe in transparency, integrity, and delivering on our
                promises. Our team works tirelessly to ensure that every aspect of our food delivery service meets the
                highest standards of quality and reliability.<br /><br />

                We take pride in curating a diverse menu featuring a wide range of cuisines, prepared with care by our
                partner restaurants and chefs. Whether you're craving traditional comfort food, exploring new flavors, or
                seeking healthier options, we've got you covered.<br /><br />

                Our commitment to excellence extends beyond the food itself. We strive to provide a seamless and convenient
                ordering experience through our user-friendly app and website. From placing your order to tracking its
                progress, we want your journey with Tumyto to be effortless and enjoyable.<br /><br />

                We deeply value our customers and their satisfaction is our top priority. We listen to your feedback,
                continuously improve our services, and ensure that every bite you take leaves you with a smile. Your trust
                in us is invaluable, and we aim to earn it with every order.<br /><br />

                Join us on this culinary adventure as we bring you the best food, crafted with passion and delivered with
                care. Let Tumyto be your companion in exploring the world of flavors and enjoying the simple pleasure of
                great food.<br /><br />

                Welcome to Tumyto, where taste meets convenience!</p>
        </div>
        <div>
            <h1>Contact Us</h1>
            <p>We value your feedback and are here to assist you with any inquiries or concerns you may have. Please find
                below the contact details for reaching us:<br /><br />

                Email: admin@tumyto.com<br /><br />
                Phone: 858-504-2266<br /><br />

                Address: AE-256, Rabindrapally, Keshtopur, Kolkata 700101<br /><br />

                Our dedicated team is ready to respond to your messages and provide prompt assistance. Whether you have
                questions about our food delivery services, need help with an order, or have any other inquiries, we are
                here to help.<br /><br />

                Feel free to reach out to us via email or phone, and we will strive to address your queries as quickly as
                possible. Please provide relevant details when contacting us, such as your name, contact information, and a
                clear description of your request or concern. This will enable us to assist you more
                effectively.<br /><br />

                We also appreciate your feedback and suggestions. If you have any thoughts or ideas on how we can enhance
                our services, please don't hesitate to share them with us. Your input helps us improve and provide an even
                better experience for our valued customers.<br /><br />

                Thank you for choosing Tumyto. We look forward to serving you and ensuring your satisfaction with our food
                delivery service.<br /><br />

                Note: Please be aware that our customer support hours may vary. We will make every effort to respond to your
                inquiries in a timely manner, but it may take longer during weekends or holidays.</p>
        </div>
    </div>
</template>
  
<script>

export default {
    data() {
        return {
            restaurantList: [],
            showAddDetailsModal: false,
            prefillData: null,
            addressList: []
        };
    },
    components: {},
    computed: {

    },
    async created() {

    },
    methods: {

    }
};
</script>
  
<style lang="scss" scoped></style>
  