<template>
    <div class="order-list-container">
        <div class="chef-order-list-container">
            <div>
                <button-comp :value="'Show Filter'" class="add-location-button" :id="'getOtpButton'" :height="'40px'"
                    :width="'100%'" :color="'#7FBD4F'" @clickEvent="() => { this.showFilter = true }"></button-comp>
                <custom-date-time-picker-modal v-if="showFilter" v-model="startDate"
                    @selected-range-date="selectedDateRange" @turnOffModal="() => { this.showFilter = false; }"
                    @fetch-list="getFilteredList"></custom-date-time-picker-modal>
            </div>
            <div v-for="(order) in getDashboardOrderList()" :key="order._id" :style="{ margin: '16px 0px 0px 0px' }">
                <div>
                    Order No: {{ order.externalOrderId }}
                </div>
                <div>
                    {{ order.kotNumber }}
                </div>
                <div>
                    <item-list-component :order="order"></item-list-component>
                </div>
                <div>
                    <p>Order Price : ₹{{ order.billInfo?.discountedFoodBill || order.billInfo?.totalFoodBill }}</p>
                    <p>Order Tax : ₹{{ order.billInfo?.taxAmount }}</p>
                </div>
            </div>
        </div>
        <div>
            <div v-if="totalPayout" class="display-flex">
                <h1>Total Payout</h1>
                <div>
                    <h1>{{ totalPayout - totalPayout * 10 / 100 }}</h1>
                    <!-- <p>{{ totalPayout }} - 10%</p> -->
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import moment from 'moment';
import socket from '../../socket';

// let audio;
export default {
    components: {},
    data() {
        return {
            filteredList: [],
            showList: true,
            showFilter: false,
            startDate: new Date,
            socket,
            showPreparedOrder: false,
            totalPayout: 0
        };
    },
    async created() {
        this.selectedDateRange();
        await this.getFilteredList();
    },
    watch: {
        filteredList() {
            this.totalPayout = this.filteredList.map(obj => obj.billInfo?.totalBill || 0).reduce((acc, curr) => acc + curr, 0);
        }
    },
    computed: {
        getDashboardOrderList() {
            return () => {
                return this.filteredList;
            }
        },
        calculateTotalBill() {
            let y = this.filteredList.map(obj => obj.billInfo?.totalBill || 0).reduce((acc, curr) => acc + curr, 0);
            return y
        }
    },
    methods: {
        selectedDateRange(dateData) {
            if (dateData) {
                let fromDateString = `${dateData.from.year}-${dateData.from.month}-${dateData.from.day} ${dateData.from.hour}:${dateData.from.minute}`;
                let toDateString = `${dateData.to.year}-${dateData.to.month}-${dateData.to.day} ${dateData.to.hour}:${dateData.to.minute}`;
                this.filterDateRange = { from: moment(fromDateString, 'YYYY-MM-DD HH:mm').toDate(), to: moment(toDateString, 'YYYY-MM-DD HH:mm').toDate() }
            } else {
                let yesterday = moment().subtract('1', 'day');
                let today = moment();
                this.filterDateRange = { from: moment(yesterday, 'YYYY-MM-DD HH:mm').toDate(), to: moment(today, 'YYYY-MM-DD HH:mm').toDate() }
            }
        },
        async getFilteredList() {
            let filteredList = await this.getApiCall('/dashboard/getOrdersRestaurantDashboard', { filterDateRange: this.filterDateRange });
            this.filteredList = filteredList
        }
    },
};
</script>
  
<style lang="scss" scoped>
.order-list-container {
    margin: 16px 16px 0px 16px;
}

.button-div {
    margin: 16px 0px 16px 0px;
    width: calc(50% - 5px);
}

.horizontal-pane {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    background-color: #f0f0f0;
    // padding: 10px;
}

.option {
    flex-grow: 1;
    flex-basis: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: hsl(51, 94%, 32%);
    // border-radius: 4px;
    // margin: 0 5px;
    cursor: pointer;
    display: flex;

    p {
        color: #e0e0e0;
        justify-content: center;
        max-width: fit-content;
        margin: 0px 7px;
    }
}

.horizontal-pane-active {
    background-color: crimson;

    p {
        color: #e0e0e0;
    }
}

.option:hover {
    // background-color: #e0e0e0;
}

/* Adjust the styles as needed */
</style>
  