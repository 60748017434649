<template>
  <div class="modal-container">
    <small-modal-component
      v-show="show"
      :isScrollable="true"
      :hideHeader="true"
    >
      <template v-slot:body>
        <div class="table-name">
          <p class="order-placed">{{ this.textToShow }}</p>
        </div>
      </template>
      <template v-slot:footer>
        <div class="footer-class">
          <div class="button-strip">
            <div class="button-div">
              <button-comp
                :id="'call-button'"
                :value="'No'"
                :height="'40px'"
                :width="'100%'"
                :color="'#C59B31'"
                @clickEvent="noEvent()"
              />
            </div>
            <div class="button-div">
              <button-comp
                :id="'direction-button'"
                :value="'Yes'"
                :height="'40px'"
                :width="'100%'"
                :color="'#7FBD4F'"
                @clickEvent="yesEvent()"
              />
            </div>
          </div>
        </div>
      </template>
    </small-modal-component>
  </div>
</template>

<script>
export default {
  name: "OrderReviewTimerModal",
  data() {
    return {
      // show: false,
    };
  },
  props: {
    show: { type: Boolean, default: true },
    textToShow: { type: String },
  },
  methods: {
    noEvent() {
      this.$emit("booleanTriggered", false);
    },
    yesEvent() {
      this.$emit("booleanTriggered", true);
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-container {
  height: 500px;
  position: absolute;
}

.modal-header {
  // font-size: 40px;
}

.order-confirm-item-list {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  margin-bottom: 10px;
  background-color: #f4f4f4;
  border-radius: 5px;
}

.footer-class {
  // position: absolute;
  bottom: 0px;
  width: calc(100% - 20px);

  .total-price {
    display: flex;
    justify-content: space-between;

    p {
      // font-size: 25px;
    }
  }

  .button-strip {
    display: flex;
    width: 100%;
    justify-content: space-between;

    .button-div {
      margin: 16px 0px 16px 0px;
      width: calc(50% - 5px);
    }

    .check-in-button-div {
      margin: 0px 16px 16px 16px;
      width: 100%;
      justify-content: space-between;
    }
  }
}

.timer-class {
  display: flex;
  justify-content: center;

  img {
    width: 100px;
    margin-top: 20px;
  }
}

.table-name {
  display: flex;
  margin-top: 16px;
  justify-content: center;

  .order-placed {
    // font-size: 30px;
  }
}

.reminder {
  display: flex;
  // font-size: 10px;
  justify-content: center;
  margin-bottom: 10px;
}
</style>
