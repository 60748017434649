<template>
  <div>
    <div class="container">
      <div v-show="showSuccessSection">
        <p>Payment Successfull</p>
      </div>
      <div v-show="showFailureSection">
        <p>Payment Not Successfull</p>
      </div>
      <div v-show="!showFailureSection && !showSuccessSection">
        <p>Payment Processing</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      billDetails: {},
      showSuccessSection: false,
      showFailureSection: false,
    };
  },
  props: {},
  watch: {
    $route: {
      handler() {
        let billId = this.$route.params.billId;
        this.getPaymentOrderDetail(billId);
      },
      immediate: true,
    },
  },
  created() {},
  methods: {
    async getPaymentOrderDetail(billId) {
      let billDetail = await this.getApiCall("/payment/getBillStatusDetail", {
        billId,
      });
      // changed for testing from === to !==
      if (billDetail?.paymentData[0]?.payment_status === "SUCCESS") {
        const orderCreateStatus = await this.postApiCall(
          "/payment/paymentDone",
          {
            billId: billDetail._id,
          }
        );
        if (orderCreateStatus === "PAYMENT_FAILED") {
          if (billDetail) {
            this.$router.push(`/delivery-checkout/${billDetail.orderId}`);
          } else {
            this.$router.push("/home");
          }
          this.errorAlert("Payment Failed");
        } else if (orderCreateStatus === "RESTAURANT_CLOSED") {
          if (billDetail) {
            this.$router.push(`/delivery-checkout/${billDetail.orderId}`);
          } else {
            this.$router.push("/home");
          }
          this.errorAlert("Payment Failed");
        } else {
          this.$router.push(`/my-orders-detail/${billDetail.orderId}`);
        }
      } else if (
        !billDetail?.paymentData ||
        !billDetail?.paymentData[0] ||
        billDetail?.paymentData[0]?.payment_status === "FAILED" ||
        billDetail?.paymentData[0]?.payment_status === "USER_DROPPED" ||
        billDetail?.paymentData[0]?.payment_status === "PENDING"
      ) {
        if (billDetail) {
          this.$router.push(`/delivery-checkout/${billDetail.orderId}`);
        } else {
          this.$router.push("/home");
        }
        this.errorAlert("Payment Failed");
      } else {
        let self = this;
        setTimeout(function () {
          self.getPaymentOrderDetail(billId);
        }, 3000);
      }
    },
  },
};
</script>
