<template>
  <div class="modal-container">
    <modal-component :isScrollable="true">
      <template v-slot:header>
        <div :style="{ display: 'flex', justifyContent: 'space-between' }">
          <h2 class="modal-header">Add Menu Category</h2>
          <p @click="turnModalOff()">x</p>
        </div>
      </template>
      <template v-slot:body>
        <div>
          <InputField id="menuCategoryName" placeholder="Category Name" />
          <div v-for="item in menuCategoryList" :key="item._id">
            <div
              :style="{
                margin: '16px',
                display: 'flex',
                justifyContent: 'space-between',
              }"
            >
              <div>{{ item.name }}</div>
              <toggle-switch
                :status="item.active === true ? 'ACTIVE' : 'DEACTIVE'"
                @changeBoolean="changeItemStatus($event, item)"
              />
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="button-strip">
          <div class="button-div">
            <ButtonComp
              :id="'call-button'"
              :value="'Add Category'"
              :height="'40px'"
              :width="'100%'"
              :color="'#C59B31'"
              @clickEvent="addMenuCategory()"
            />
          </div>
        </div>
      </template>
    </modal-component>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      menuCategoryList: [],
    };
  },
  props: {},
  async created() {
    this.menuCategoryList = await this.getApiCall(
      "/restaurant/getMenuCategory"
    );
  },
  methods: {
    async changeItemStatus(active, item) {
      await this.postApiCall("/restaurant/changeCategoryItemStatus", {
        active,
        itemId: item._id,
      });
      // this.menuList = result;
    },
    async addMenuCategory() {
      let categoryName = document.querySelector(
        "#input-comp-menuCategoryName"
      ).value;
      let result = await this.postApiCall("/restaurant/addMenuCategory", {
        name: categoryName,
      });

      if (result === "CATEGORY_ALREADY_EXIST") {
        this.errorAlert("Category Already Exist");
      }

      // window.location.reload();
      this.menuCategoryList = await this.getApiCall(
        "/restaurant/getMenuCategory"
      );
    },
    turnModalOff() {
      this.$emit("turnCategoryModalOff");
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-container {
  height: 500px;
}

.modal-header {
  // font-size: 40px;
}
</style>
