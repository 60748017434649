// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import { createApp } from "vue";
// import { createGmap, loadGmapApi } from 'vue3-google-map';
import App from "./App";
import { Skeletor } from "vue-skeletor";
import router from "./router";
// import wholeStore from './store/whole.store';
import orderStore from "./store/order.store";
// import VueGoogleMaps from '@fawmi/vue-google-maps'
// eslint-disable-next-line no-unused-vars
import OneSignal from "onesignal-cordova-plugin";

// let app = createApp(App);

// app.use(router);

import UtilityMixin from "./mixin/utilityMixin";
import ApiMixin from "./mixin/apiMixin";

import CounterEdit from "./components/CounterEdit.vue";
import ButtonComp from "./components/ButtonComp.vue";
import InputField from "./components/InputField.vue";
import ToggleSwitch from "./components/ToggleSwitch.vue";
import CustomDateTimePickerModal from "./components/modals/CustomDateTimePickerModal.vue";
import ImageButton from "./components/ImageButton.vue";
import ItemListComponent from "./components/ItemListComponent.vue";
import TopNavbar from "./components/partials/TopNavbar.vue";
import BottomNavbar from "./components/partials/BottomNavbar.vue";
import ModalComponent from "./components/ModalComponent.vue";
import LoaderComponent from "./components/LoaderComponent.vue";
import SideBar from "./components/SideBar.vue";
import SmallModalComponent from "./components/SmallModalComponent.vue";
// import { PulseLoader } from 'vue-spinner/src/PulseLoader.vue'

const app = createApp(App);

// loadGmapApi({
//     key: 'YOUR_GOOGLE_MAPS_API_KEY',
//     libraries: ['places'], // Optional: Add any additional libraries you need
// }).then(() => {
//     app.use(createGmap);
// });

app.component("ButtonComp", ButtonComp);
app.component("InputField", InputField);
app.component("ModalComponent", ModalComponent);
app.component("SideBar", SideBar);
app.component("CustomDateTimePickerModal", CustomDateTimePickerModal);
app.component("TopNavbar", TopNavbar);
app.component("SmallModalComponent", SmallModalComponent);
app.component("BottomNavbar", BottomNavbar);
app.component("LoaderComponent", LoaderComponent);
app.component("ImageButton", ImageButton);
app.component("CounterEdit", CounterEdit);
app.component("ToggleSwitch", ToggleSwitch);
app.component("ItemListComponent", ItemListComponent);

app.component(Skeletor.name, Skeletor);

app.use(orderStore);

app.mixin(UtilityMixin);
app.mixin(ApiMixin);

app.use(router).mount("#app");

document.addEventListener("deviceready", OneSignalInit, false);
async function OneSignalInit() {
  // Remove this method to stop OneSignal Debugging
  window.plugins.OneSignal.Debug.setLogLevel(6);

  // Uncomment to set OneSignal visual logging to VERBOSE
  // window.plugins.OneSignal.Debug.setAlertLevel(6);

  // Replace YOUR_ONESIGNAL_APP_ID with your OneSignal App ID
  window.plugins.OneSignal.initialize(process.env.VUE_APP_ONESIGNAL_APP_ID);

  //Adds an event listener for clicks on notifications
  const listener = (event) => {
    // eslint-disable-next-line no-unused-vars
    const notificationPayload = JSON.stringify(event);
  };

  if ((await window.plugins.OneSignal.User.getExternalId()) === null) {
    await window.plugins.OneSignal.login();
  }

  window.plugins.OneSignal.Notifications.addEventListener("click", listener);

  //Prompts the user for notification permissions.
  //    * Since this shows a generic native prompt, we recommend instead using an In-App Message to prompt for notification permission (See step 6) to better communicate to your users what notifications they will get.
  window.plugins.OneSignal.Notifications.requestPermission(true).then(
    (accepted) => {
      console.log("User accepted notifications: " + accepted);
    }
  );
}
