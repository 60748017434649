const dishList = [
  {
    name: "Biryani",
    tag: "biryani",
    image: "/images/menuItem/biryani-icon.svg",
  },
  {
    name: "Chicken",
    tag: "chicken",
    image: "/images/menuItem/chicken-icon.svg",
  },
  { name: "Rolls", tag: "roll", image: "/images/menuItem/roll-icon.svg" },
  { name: "Pizza", tag: "pizza", image: "/images/menuItem/pizza-icon.svg" },
  { name: "Momo", tag: "momo", image: "/images/menuItem/momo-icon.svg" },
  { name: "Burger", tag: "burger", image: "/images/menuItem/burger-icon.svg" },
  { name: "Meals", tag: "meal", image: "/images/menuItem/meal-icon.svg" },
  { name: "Kebab", tag: "kebab", image: "/images/menuItem/kebab-icon.svg" },
];

const restaurantTagList = [
  ...dishList,
  { name: "Recommended", tag: "recommended", image: "" },
  { name: "Veg", tag: "veg", image: "" },
  { name: "Non Veg", tag: "nonveg", image: "" },
];

export { dishList, restaurantTagList };
