<template>
    <div :style="{display: 'flex', flexDirection: 'column', height: '100vh'}">
        <div :style="{display: 'flex', flex: 1, flexDirection: 'column',alignItems: 'center', justifyContent: 'center'}">
            <div class="numbers">{{dataCount.orderCount}}</div>
            <div class="label-text">Orders Delivered</div>
        </div>
        <div :style="{flex: 3, display: 'flex', justifyContent: 'center', alignItems: 'center'}">
            <div :style="{display: 'flex', flex: 1, flexDirection: 'column',alignItems: 'center', justifyContent: 'center'}">
                <div class="numbers">{{ dataCount.userCount }}</div>
                <div class="label-text">Users</div>
            </div>
            <div :style="{display: 'flex', flex: 1, flexDirection: 'column',alignItems: 'center', justifyContent: 'center'}">
                <div class="numbers">{{dataCount.restaurantCount}}</div>
                <div class="label-text">Restaurant</div>
            </div>
        </div>
  </div>
</template>
  
<script>

export default {
    components: {},
    data() {
        return {
            dataCount: { 
                 orderCount: 0,
                 userCount: 0,
                 restaurantCount: 0
            }
        };
    },
    async created() {
        let countData = await this.getApiCall('/superAdmin/hd');
        this.dataCount = countData;
    },
    methods: {
        
    },
};
</script>
  

<style lang="scss" scoped>

.label-text {
    font-size: 60px;
}

.numbers {
    font-size: 80px;
}

</style>