<template>
  <div class="modal-container">
    <small-modal-component
      v-show="show"
      :isScrollable="true"
      :hideHeader="false"
    >
      <template v-slot:header>
        <div :style="{ display: 'flex', justifyContent: 'space-between' }">
          <h2 class="modal-header">Bill Details</h2>
          <p @click="turnModalOff()">x</p>
        </div>
      </template>
      <template v-slot:body>
        <div>
          <div class="display-flex">
            <p class="bill-label">Total</p>
            <div
              v-if="
                this.billDetails.discountedFoodBill !==
                this.billDetails.totalFoodBill
              "
              :style="{ display: 'flex' }"
            >
              <p>₹ {{ this.billDetails.discountedFoodBill }}</p>
              &nbsp;
              <p>
                <s>₹ {{ this.billDetails.totalFoodBill }}</s>
              </p>
            </div>
            <div v-else>
              <p>₹ {{ this.billDetails.totalFoodBill }}</p>
            </div>
          </div>
        </div>
        <div v-if="this.billDetails.foodDiscountAmount">
          <div class="display-flex discount-strip">
            <p class="bill-label">Discount on Food</p>
            <p>
              - ₹
              {{ this.billDetails.foodDiscountAmount }}
            </p>
          </div>
        </div>
        <div v-if="this.billDetails.deliveryBill" class="display-flex">
          <p class="bill-label">Delivery Cost</p>
          <p>₹ {{ this.billDetails.deliveryBill }}</p>
        </div>
        <div v-if="this.billDetails.deliveryDiscountAmount">
          <div class="display-flex discount-strip">
            <p class="bill-label">Discount on Delivery</p>
            <p>
              - ₹
              {{ this.billDetails.deliveryDiscountAmount }}
            </p>
          </div>
        </div>

        <div class="display-flex">
          <p class="bill-label">Tax and Other Charges</p>
          <p>
            ₹
            {{ totalTax(this.billDetails) }}
          </p>
        </div>
      </template>
    </small-modal-component>
  </div>
</template>

<script>
export default {
  name: "OrderReviewTimerModal",
  data() {
    return {
      // show: false,
    };
  },
  props: {
    show: { type: Boolean, default: true },
    billDetails: { type: Object },
  },
  created() {},
  methods: {
    totalTax(billDetails) {
      return parseFloat(
        parseFloat(billDetails.taxAmount) +
          parseFloat(billDetails.deliveryTaxAmount)
      ).toFixed(2);
    },
    turnModalOff() {
      this.$emit("turnCustomOrderModalOff");
    },
    noEvent() {
      this.$emit("booleanTriggered", false);
    },
    yesEvent() {
      this.$emit("booleanTriggered", true);
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-container {
  height: 500px;
  position: absolute;
}

.modal-header {
  // font-size: 40px;
}

.order-confirm-item-list {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  margin-bottom: 10px;
  background-color: #f4f4f4;
  border-radius: 5px;
}

.footer-class {
  position: absolute;
  bottom: 0px;
  width: calc(100% - 20px);

  .total-price {
    display: flex;
    justify-content: space-between;

    p {
      // font-size: 25px;
    }
  }

  .button-strip {
    display: flex;
    width: 100%;
    justify-content: space-between;

    .button-div {
      margin: 16px 0px 16px 0px;
      width: calc(50% - 5px);
    }

    .check-in-button-div {
      margin: 0px 16px 16px 16px;
      width: 100%;
      justify-content: space-between;
    }
  }
}

.timer-class {
  display: flex;
  justify-content: center;

  img {
    width: 100px;
    margin-top: 20px;
  }
}

.table-name {
  display: flex;
  margin-top: 16px;
  justify-content: center;

  .order-placed {
    // font-size: 30px;
  }
}

.reminder {
  display: flex;
  // font-size: 10px;
  justify-content: center;
  margin-bottom: 10px;
}
</style>
