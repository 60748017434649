<template>
  <div :class="['restaurant-card']" @click="gotoMenu()">
    <img
      class="restaurant-image"
      :src="data.image"
      alt="Image"
      :style="{ backgroundColor: 'white', width: '100%', height: '300px' }"
    />
    <div :class="['overlay-text', data.status === 'OFFLINE' ? 'inactive' : '']">
      <h3 class="restaurant-name">{{ data.name }}</h3>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
    gotoUrl: {
      type: String,
      required: true,
    },
  },
  methods: {
    gotoMenu() {
      if (this.data.status === "ONLINE") {
        this.$router.push(this.gotoUrl);
      } else {
        this.errorAlert("Restaurant is closed right now.");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.restaurant-card {
  width: 100px;
  margin-right: 10px;
  height: 80px;
  background-color: #610ad2;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 16px;

  position: relative;
  display: inline-block;
}

.restaurant-image {
  width: 100%;
  height: 100px;
  object-fit: cover;
}

.restaurant-info {
  padding: 12px;
}

.restaurant-name {
  margin: 0;
  font-size: 12px;
  font-weight: 600;
}

.overlay-text {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  /* transform: translate(-50%, -50%); */
  background-color: rgba(0, 0, 0, 0.35);
  color: #fff;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .restaurant-cuisine,
  .restaurant-address {
    margin: 6px 0;
    // font-size: 14px;
    color: #ffffff;
  }

  .restaurant-ratings {
    display: flex;
    align-items: center;
  }

  .restaurant-rating {
    margin: 0;
    // font-size: 16px;
    font-weight: bold;
    color: #ff6b6b;
  }

  .restaurant-votes {
    margin-left: 4px;
    // font-size: 14px;
    color: #666;
  }
}

.overlay-text.inactive {
  background-color: rgba(255, 255, 255, 0.6) !important;
}
</style>
