<template>
  <div class="modal-container">
    <modal-component v-show="show" :isScrollable="true">
      <template v-slot:header>
        <p @click="turnModalOff()">x</p>
        <div>Create Coupons</div>
      </template>
      <template v-slot:body>
        <div>
          <div>
            <input-field
              type="text"
              :id="'couponField'"
              :onlyUpperCase="true"
              @change-handler="
                ($events) => {
                  this.couponCode = $events;
                }
              "
              placeholder="Enter Coupon Name"
            />
          </div>
          <div>
            <input-field
              type="number"
              :id="'maxAmount'"
              :onlyUpperCase="true"
              @change-handler="
                ($events) => {
                  this.maxAmount = $events;
                }
              "
              placeholder="Max Discount Amount"
            />
          </div>
          <div>
            <input-field
              type="number"
              :id="'amount'"
              :onlyUpperCase="true"
              @change-handler="
                ($events) => {
                  this.amount = $events;
                }
              "
              placeholder="Discount Amount"
            />
          </div>
          <div>
            <select v-model="deductionType">
              <option value="FLAT_AMOUNT">Flat Amount</option>
              <option value="PERCENT">Percentage</option>
            </select>
          </div>
          <div>
            <label>
              <input
                type="radio"
                value="REGULAR"
                v-model="selectedDiscountFrequency"
              />
              Regular
            </label>
            <label>
              <input
                type="radio"
                value="ONE_TIME"
                v-model="selectedDiscountFrequency"
              />
              One Time
            </label>
            <label>
              <input
                type="radio"
                value="CUSTOMER_SPECIFIC"
                v-model="selectedDiscountFrequency"
              />
              Customer Specific (NA)
            </label>
            <label>
              <input
                type="radio"
                value="RESTAURANT_SPECIFIC"
                v-model="selectedDiscountFrequency"
              />
              Restaurant Specific (NA)
            </label>
          </div>
          <div>
            <div v-if="selectedDiscountFrequency === 'RESTAURANT_SPECIFIC'">
              <select v-model="selectedRestaurant">
                <option
                  v-for="(restaurant, index) in restaurantList"
                  :key="index"
                  :value="restaurant"
                >
                  {{ restaurant.name }}
                </option>
              </select>
            </div>
          </div>
          <div>
            <div v-for="coupon in couponList" :key="coupon._id">
              <div
                v-if="coupon.couponCode && regexMatch(coupon.couponCode)"
                class="coupon-card"
              >
                <div class="coupon-card-left">
                  <p>Coupon Code: {{ coupon.couponCode }}</p>
                  <p>Frequency: {{ coupon.frequency }}</p>
                  <p>Deduction Type: {{ coupon.deductionType }}</p>
                </div>
                <div class="coupon-card-right">
                  <p>Max Amount: {{ coupon.maxAmount }}</p>
                  &nbsp;
                  <toggle-switch
                    :status="coupon.status"
                    @change="updateCouponStatus(coupon)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="footer-class">
          <div class="action-button">
            <button-comp
              :value="'Store Coupon'"
              class="store-coupon-button"
              :height="'40px'"
              :width="'100%'"
              :color="'crimson'"
              @clickEvent="storeCoupons()"
            ></button-comp>
          </div>
        </div>
      </template>
    </modal-component>
  </div>
</template>

<!-- <script src="https://unpkg.com/vue3-google-map"></script> -->
<script>
import { errorAlert, infoAlert } from "@/utility";
// import { GoogleMap, Marker } from "vue3-google-map";

export default {
  data() {
    return {
      selectedDiscountFrequency: "",
      couponCode: "",
      maxAmount: "",
      amount: "",
      couponList: [],
      restaurantList: [],
      selectedRestaurant: "",
      deductionType: "",
    };
  },
  components: {},
  props: {
    show: { type: Boolean, default: true },
    prefill: { type: Object, default: null },
  },
  computed: {},
  watch: {},
  mounted() {
    this.getCouponList();
  },
  async created() {
    this.restaurantList = await this.getApiCall(
      "/superAdmin/getRestaurantList"
    );
  },
  methods: {
    changeItemStatus() {},
    regexMatch(coupon) {
      let regex = new RegExp(this.couponCode);
      return regex.test(coupon);
    },
    turnModalOff() {
      this.$emit("turnShowOrderRemoveModalOff");
    },
    async updateCouponStatus(itemDetails) {
      let toSetStatus = "DEACTIVE";
      if (itemDetails?.status === "DEACTIVE") {
        toSetStatus = "ACTIVE";
      }
      let response = await this.postApiCall("/superAdmin/updateCouponStatus", {
        status: toSetStatus,
        couponCode: itemDetails?.couponCode,
      });
      if (response === "COUPON_STATUS_UPDATED") {
        infoAlert("Updated Successfully");
      } else {
        errorAlert("Sorry could not update coupon");
      }
    },
    async storeCoupons(itemDetails) {
      let toSetStatus;
      if (itemDetails?.status === "DEACTIVE") {
        toSetStatus = "ACTIVE";
      }

      let data = {
        couponCode: itemDetails?.couponCode || this.couponCode,
        maxAmount: itemDetails?.maxAmount || this.maxAmount,
        amount: itemDetails?.amount || this.amount,
        frequency: itemDetails?.frequency || this.selectedDiscountFrequency,
        couponType: itemDetails?.couponType || this.selectedDiscountFrequency,
        status: toSetStatus || "DEACTIVE",
        deductionType: itemDetails?.deductionType || this.deductionType,
      };

      if (this.selectedDiscountFrequency === "RESTAURANT_SPECIFIC") {
        data.restaurantId = this.selectedRestaurant._id;
      }
      let response = await this.postApiCall("/superAdmin/storeCoupon", data);
      if (response === "COUPON_ADDED") {
        infoAlert("Updated Successfully");
      } else {
        errorAlert("Sorry could not update coupon");
      }
    },
    async getCouponList() {
      let couponList = await this.getApiCall("/superAdmin/getCouponList");
      this.couponList = couponList;
    },
  },
};
</script>

<style lang="scss" scoped>
.coupon-card {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  border: 1px solid grey;
  padding: 16px;
  border-radius: 5px;

  .coupon-card-right {
    display: flex;
    // flex-direction: column;
    // align-content: flex-end;
    align-items: end;
    flex-wrap: wrap;
  }
}
</style>
