import { createStore } from "vuex";
import _ from "underscore";

let orderStore = createStore({
  state: {
    searchedMenuItem: [],
    orders: [],
    filterList: [],
    currentActiveOrders: [],
    restaurant: { id: "", name: "" },
    menuItem: [],
    amount: 0,
    restaurantId: "",
    categoryConfig: [],
    itemMixCount: {},
    processedOrderList: [],
    tableId: "",
    pleaseCheckIn: false,
    showStatusBar: false,
    showLoaderScreen: false,
    showTopNavbar: true,
    incomingOrderList: [],
    acceptedOrderList: [],
    preparedOrderList: [],
    userData: "NO_DATA",
  },
  mutations: {
    UPDATE_RESTAURANT_SIDE_ORDER_LIST(state, orderList) {
      state.incomingOrderList = orderList.incomingOrderList;
      state.acceptedOrderList = orderList.acceptedOrderList;
      state.preparedOrderList = orderList.preparedOrderList;
    },
    UPDATE_LOADER_SCREEN(state, showLoaderScreen) {
      state.showLoaderScreen = showLoaderScreen;
    },
    UPDATE_STATUS_BAR(state, statusBar) {
      state.showStatusBar = statusBar;
    },
    UPDATE_USER_DETAILS(state, value) {
      state.userData = value;
    },
    UPDATE_STATE_VALUE(state, data) {
      state[data.key] = data.value;
    },
    SET_SEARCHED_LIST(state, list) {
      state.searchedMenuItem = list;
    },
    SET_FILTERED_LIST(state, list) {
      state.filteredList = list;
      state.searchedMenuItem = list;
    },
    editItem(state, q) {
      let {
        itemIdentifier,
        action,
        tagsList,
        itemName,
        itemPrice,
        discountPrice,
        itemTags,
      } = q;
      if (action === "plus") {
        state.orders.push({
          itemIdentifier,
          customization: tagsList,
          itemName,
          discountPrice,
          itemPrice,
          itemTags,
          status: "ORDERED",
        });
      } else if (action === "minus") {
        state.orders[itemIdentifier] = state.orders[itemIdentifier]
          ? state.orders[itemIdentifier] - 1
          : 0;
      }
    },
    setRestaurant(state, { restaurantId, restrauntName }) {
      state.restaurant.id = restaurantId;
      state.restaurant.name = restrauntName;
    },
    UPDATE_CATEGORY_CONFIG(state, cateogry) {
      state.categoryConfig = cateogry;
    },
    UPDATE_MENU_LIST(state, menuItem) {
      state.menuItem = menuItem;
      state.searchedMenuItem = menuItem;
      state.filteredList = menuItem;
    },
    UPDATE_ORDER_LIST(state, { processedOrderList, itemMixCount }) {
      state.processedOrderList = processedOrderList;
      state.itemMixCount = itemMixCount;
    },
    UPDATE_CURRENT_ORDER_BILL(state, amount) {
      state.amount = amount;
    },
    UPDATE_CURRENT_ORDERS(state, orderList) {
      state.currentActiveOrders = orderList;
    },
    UPDATE_ORDER_RAW_LIST(state, orderList) {
      state.orders = orderList;
    },
    UPDATE_SESSION_DETAILS(state, data) {
      let { tableId, restaurantId } = data;
      state.tableId = tableId;
      state.restaurantId = restaurantId;
    },
    RESET_ORDERS(state) {
      state.orders = [];
      state.currentActiveOrders = [];
      state.amount = 0;
      state.itemMixCount = {};
      state.processedOrderList = [];
      state.filteredList = [];
      state.restaurant = { id: "", name: "" };
      state.menuItem = [];
      state.restaurantId = "";
      state.categoryConfig = [];
      state.tableId = "";
      state.pleaseCheckIn = false;
    },
  },
  actions: {
    setStatusBar({ commit }, value) {
      commit("UPDATE_STATUS_BAR", value);
    },
    setLoaderScreen({ commit }, value) {
      commit("UPDATE_LOADER_SCREEN", value);
    },
    setUserData({ commit }, value) {
      commit("UPDATE_USER_DETAILS", value);
    },
    resetOrders({ commit }) {
      commit("RESET_ORDERS");
    },
    getFilteredList({ commit }, filter) {
      const filteredWords = this.state.menuItem.filter((word) => {
        const regex = new RegExp(`\\b${filter}\\b`, "i");
        return regex.test(word.itemTags);
      });
      commit("SET_FILTERED_LIST", filteredWords);
      // this.dispatch('getSearchedList', '');
    },
    getMenuCategoryFilteredList({ commit }, menuCategoryIdentifier) {
      const filteredWords = this.state.menuItem.filter((word) => {
        const regex = new RegExp(`\\b${menuCategoryIdentifier}\\b`, "i");
        return regex.test(word.menuCategories);
      });
      commit("SET_FILTERED_LIST", filteredWords);
      // this.dispatch('getSearchedList', '');
    },
    getSearchedList({ commit }, phrase) {
      const regex = new RegExp(phrase, "i");
      let y = this.state.menuItem.filter(
        (item) =>
          regex.test(item.name) ||
          (item?.itemTags && item.itemTags?.some((tag) => regex.test(tag)))
      );
      commit("SET_SEARCHED_LIST", y);
    },
    setSessionDetails({ commit }, { tableId, restaurantId }) {
      commit("UPDATE_SESSION_DETAILS", { tableId, restaurantId });
    },
    async getCategoryConfig({ commit }, result) {
      // let result = await this.getApiCall('getCategoryConfig', { restaurantId });
      // Meteor.call("getCategoryConfig", function(err, result) {
      commit("UPDATE_CATEGORY_CONFIG", result);
      // });
    },
    placeOrder({ commit }) {
      let orders = this.state.orders;
      let processedOrderList = [];
      let itemMixCount = [];
      // let itemMixPrice = [];
      for (let i = 0; i < orders.length; i++) {
        let item = orders[i];
        let customString = getACustomisationString(item.customization);
        if (isOrderItemPresent(processedOrderList, item)) {
          itemMixCount[`${item.itemIdentifier},${customString}`]++;
        } else {
          processedOrderList.push({
            ...item,
            discountPrice: item.discountPrice,
            customString,
          });
          itemMixCount[`${item.itemIdentifier},${customString}`] = 1;
        }
      }
      let menuList = this.state.menuItem;
      let orderedItemArray = processedOrderList;
      let amount = 0;
      for (let j = 0; j < orderedItemArray.length; j++) {
        let itemIdentifier = orderedItemArray[j].itemIdentifier;
        let neededMenuItemObj = _.find(menuList, function (item) {
          return item.identifier === itemIdentifier;
        });

        let customisationprice = getCustomisationPrice(
          orderedItemArray[j].customization
        );

        let itemTotal =
          ((neededMenuItemObj.discountPrice
            ? parseFloat(neededMenuItemObj.discountPrice)
            : parseFloat(neededMenuItemObj.itemPrice)) +
            (customisationprice ? parseFloat(customisationprice) : 0)) *
          itemMixCount[`${itemIdentifier},${orderedItemArray[j].customString}`];

        amount = amount + itemTotal;
      }
      commit("UPDATE_CURRENT_ORDER_BILL", amount);
      commit("UPDATE_ORDER_LIST", { processedOrderList, itemMixCount });
      // commit("UPDATE_ORDER_RAW_LIST", []);
    },
    editItem({ commit }, q) {
      commit("editItem", q);
    },
    setRestaurant({ commit }, q) {
      commit("setRestaurant", q);
    },
    getRestrauntMenu({ commit }, menu) {
      commit("UPDATE_MENU_LIST", menu);
    },
    removeCertainItemCombo({ commit }, { itemIdentifier }) {
      let orders = this.state.orders;
      let newOrderArray = [];
      let removed = false;
      for (let i = 0; i < orders.length; i++) {
        let orderItem = orders[i];
        if (
          orderItem.itemIdentifier !== itemIdentifier ||
          // orderItem.customization !== customization ||
          removed
        ) {
          newOrderArray.push(orders[i]);
        } else {
          removed = true;
        }
      }
      commit("UPDATE_ORDER_RAW_LIST", newOrderArray);
    },
    setRestaurantSideOrderList({ commit }, orderList) {
      commit("UPDATE_RESTAURANT_SIDE_ORDER_LIST", orderList);
    },
  },
});

const isOrderItemPresent = (processedOrderList, item) => {
  let itemFound = false;
  processedOrderList.map(function (processedItem) {
    if (
      processedItem.itemIdentifier === item.itemIdentifier &&
      _.isEqual(processedItem.customization, item.customization)
    ) {
      itemFound = true;
    }
  });
  return itemFound;
};

export const getACustomisationString = (customisation) => {
  let reqString = [];
  customisation.map((item) => {
    reqString.push(item.name);
  });
  return reqString.join(",");
};

const getCustomisationPrice = (customisation) => {
  let price = 0;
  customisation.map((item) => {
    if (item.price && item.price !== "undefined") {
      price = price + parseFloat(item.price);
    }
  });
  return price;
};

export const getACustomisationStringWithSpace = (customisation) => {
  let reqString = [];
  customisation.map((item) => {
    reqString.push(item.name);
  });
  return reqString.join(", ");
};

export default orderStore;
