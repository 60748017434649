<template>
  <div class="button-container">
    <img
      :src="src"
      :style="btnStyles"
      @click="buttonClicked"
      :disabled="disabled"
    />
    <p></p>
    <button-confirmation-modal
      v-if="showModal"
      :textToShow="textToShow"
      @booleanTriggered="booleanTriggered"
    ></button-confirmation-modal>
  </div>
</template>

<script>
import ButtonConfirmationModal from "../pages/modals/ButtonConfirmationModal.vue";

export default {
  name: "ImageButton",
  components: { ButtonConfirmationModal },
  data() {
    return {
      showModal: false
    };
  },
  props: {
    disabled: { type: Boolean, default: false },
    id: { type: String, required: false },
    type: { type: String, default: "text" },
    src: { type: String, default: "" },
    value: { type: String, default: "Submit" },
    color: { type: String, default: "" },
    height: { type: String, default: "" },
    margin: { type: String, default: "" },
    width: { type: String, default: "" },
    showConfirmationModal: { type: Boolean, default: false },
    textToShow: { type: String }
  },
  computed: {
    btnStyles() {
      return {
        "background-color": this.color,
        height: `${this.height}`,
        width: `${this.width}`,
        margin: `${this.margin}`
      };
    }
  },
  created() {},
  methods: {
    buttonClicked() {
      if (this.showConfirmationModal) {
        this.showModal = true;
      } else {
        this.$emit("click");
      }
    },
    booleanTriggered(value) {
      if (value) {
        this.showModal = false;
        this.$emit("click");
      } else {
        this.showModal = false;
      }
    }
  }
};
</script>

<style lang="scss">
.button-container {
  display: flex;
  //   justify-content: center;
}

// button {
//   padding: 20px !important;
//   border-radius: 20px !important;
//   border: none !important;
//   background-color: rgb(165, 30, 30) !important;
//   color: white !important;
//   margin-top: 2.5rem !important;
// }

button {
  //   padding: 20px;
  border-radius: 5px;
  border: none;
  background-color: v-bind(colour);
  color: white;
  // margin-top: 2.5rem;
}
</style>
