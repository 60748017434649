import Swal from "sweetalert2";

const convertToIdentifier = (str) => {
  let a = str && str.trim().split(" ");
  let y = a.map((item) => {
    let r = item && item.trim();
    let y = r.toUpperCase();
    const pattern = /[^\w\s]/gi;
    const u = y.replace(pattern, "");
    return u;
  });
  let b = y.join("_");
  return b;
};

// const parsedDataforCharts = (dataType, dataArray) => {
//   if (dataType === "BILL_DATA_FOR_MANAGER") {
//     return {
//       xAxis: [],
//       yAxis: []
//     };
//   }
// };

const hasEntitlement = (entitlement) => {
  const restaurantData = window.localStorage.getItem("restaurantData");
  return (
    !!restaurantData &&
    restaurantData.entitlements &&
    restaurantData.entitlements.includes(entitlement)
  );
};

const errorAlert = (errText) => {
  Swal.fire({
    text: errText,
    target: "#custom-target",
    toast: true,
    position: "bottom",
    showConfirmButton: false,
    timer: 3000,
    color: "#ffffff",
    background: "#ff0000",
    padding: "5px",
    margin: "5px",
  });
};

const infoAlert = (infoText) => {
  Swal.fire({
    text: infoText,
    target: "#custom-target",
    toast: true,
    position: "bottom",
    showConfirmButton: false,
    timer: 3000,
    color: "#ffffff",
    background: "#009933",
    padding: "5px",
    margin: "5px",
  });
};

export { convertToIdentifier, hasEntitlement, errorAlert, infoAlert };
