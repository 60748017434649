<template>
  <div class="modal-container">
    <modal-component v-show="show" :isScrollable="true">
      <template v-slot:header>
        <p @click="turnModalOff()">x</p>
        <p class="modal-header">Set Restaurant to User</p>
      </template>
      <template v-slot:body>
        <select v-model="selectedRestaurant">
          <option
            v-for="(restaurant, index) in restaurantList"
            :key="index"
            :value="restaurant"
          >
            {{ restaurant.name }}
          </option>
        </select>
        <input-field
          :type="'number'"
          @change-handler="
            ($events) => {
              this.phoneNumber = $events;
            }
          "
          placeholder="Enter Phone Number"
        />
      </template>
      <template v-slot:footer>
        <div class="footer-class">
          <div class="action-button">
            <button-comp
              :value="'Send and Test Notification'"
              class="add-location-button"
              :height="'40px'"
              :width="'100%'"
              :color="'crimson'"
              @clickEvent="sendTestNotification()"
            ></button-comp>
          </div>
          <div class="action-button">
            <button-comp
              :value="'Change Auto Status'"
              class="add-location-button"
              :height="'40px'"
              :width="'100%'"
              :color="'crimson'"
              @clickEvent="changeAutoStatus()"
            ></button-comp>
          </div>
          <div class="action-button">
            <button-comp
              :value="'Change Prod Status'"
              class="add-location-button"
              :height="'40px'"
              :width="'100%'"
              :color="'crimson'"
              @clickEvent="setLiveStatusToRestaurant()"
            ></button-comp>
          </div>
          <div class="action-button">
            <button-comp
              :value="'Set Restaurant To User'"
              class="add-location-button"
              :height="'40px'"
              :width="'100%'"
              :color="'crimson'"
              @clickEvent="setRestaurantToUser()"
            ></button-comp>
          </div>
        </div>
      </template>
    </modal-component>
  </div>
</template>

<script>
export default {
  data() {
    return {
      restaurantList: [],
      selectedRestaurant: "",
      phoneNumber: "",
    };
  },
  props: {
    show: { type: Boolean, default: true },
  },
  async created() {
    this.restaurantList = await this.getApiCall(
      "/superAdmin/getRestaurantList"
    );
  },
  methods: {
    async sendTestNotification() {
      let result = await this.postApiCall("/superAdmin/testNotification", {
        selectedRestaurant: this.selectedRestaurant,
      });
      this.infoAlert(result);
    },
    async changeAutoStatus() {
      let result = await this.postApiCall("/superAdmin/changeAutoStatus", {
        selectedRestaurant: this.selectedRestaurant,
      });
      this.infoAlert(result);
    },
    turnModalOff() {
      this.$emit("turnShowOrderRemoveModalOff");
    },
    async setLiveStatusToRestaurant() {
      let result = await this.postApiCall(
        "/superAdmin/setLiveStatusToRestaurant",
        { selectedRestaurant: this.selectedRestaurant }
      );
      this.infoAlert(result);
    },
    async setRestaurantToUser() {
      let result = await this.postApiCall("/superAdmin/setRestaurantToUser", {
        phoneNumber: this.phoneNumber,
        selectedRestaurant: this.selectedRestaurant,
      });
      this.infoAlert(result);
    },
  },
};
</script>

<style lang="scss" scoped>
.action-button {
  margin-top: 10px;
}

.background-body {
  // background-color: crimson;
}

.modal-container {
  height: 500px;
}

.modal-header {
  font-size: 40px;
}

.order-confirm-item-list {
  padding: 20px;
  margin-bottom: 10px;
  background-color: #f4f4f4;
  border-radius: 5px;

  .item-name-quantity-price {
    display: flex;
    justify-content: space-between;
  }
}

.display-flex {
  display: flex;
  justify-content: space-between;
}

.footer-class {
  .total-price {
    display: flex;
    justify-content: space-between;

    p {
      font-size: 25px;
    }
  }

  .button-strip {
    display: flex;
    width: 100%;
    justify-content: space-between;

    .button-div {
      margin: 16px 0px 16px 0px;
      width: calc(50% - 5px);
    }

    .check-in-button-div {
      margin: 0px 16px 16px 16px;
      width: 100%;
      justify-content: space-between;
    }
  }

  .reminder {
    display: flex;
    font-size: 10px;
    justify-content: center;
    margin-bottom: 10px;
  }
}
</style>
