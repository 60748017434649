<template>
  <div
    :class="`input-comp-container ${noExtraSpacing ? 'no-extra-spacing' : ''} ${
      error ? 'error' : ''
    }`"
  >
    <label for="input-comp">
      <span v-if="label">
        {{ label }}
      </span>
      <div
        :style="{
          display: 'flex',
          alignItems: 'center',
          marginTop: '8px',
          padding: '8px 12px',
          backgroundColor: 'white',
          borderRadius: '5px',
          border: '1px solid #D76E19',
          ...style,
        }"
      >
        <img
          v-if="img"
          :src="`images/${img}`"
          :style="{ width: '20px', height: '20px', marginRight: '8px' }"
        />
        <div
          v-if="img"
          :style="{
            height: '20px',
            width: '0.5px',
            border: '1px solid #D76E19',
          }"
        ></div>
        <input
          autocomplete="off"
          autocorrect="off"
          autocapitalize="off"
          spellcheck="false"
          :maxlength="maxLength"
          :id="`input-comp-${id}`"
          :disabled="disabled"
          :name="name"
          :type="type"
          :style="{
            outline: 'none',
            border: '0px solid white',
            marginLeft: '8px',
            // '-moz-appearance': 'textfield',
            // '-webkit-appearance': 'none',
          }"
          :value="inputValue"
          :class="`binded-class-${bindedClass}`"
          :placeholder="placeholder"
          @input="changeHandler"
          @blur="$emit('blur')"
        />
      </div>
      <div v-if="error" class="error-label">
        <img src="/input-error-icon.svg" />
        <span>{{ _(error) }}</span>
      </div>
    </label>
  </div>
</template>

<script>
export default {
  data() {
    return {
      inputValue: "",
    };
  },
  name: "InputField",
  props: {
    disabled: { type: Boolean, default: false },
    id: { type: String, required: true },
    bindedClass: { type: String, required: false },
    value: { type: [String, Number], default: "" },
    type: { type: String, default: "text" },
    name: { type: String, default: "text" },
    placeholder: { type: String, default: "" },
    label: { type: String },
    img: { type: String },
    maxLength: { type: String },
    style: { type: Object },
    error: { type: String, default: undefined },
    noExtraSpacing: { type: Boolean, default: false },
    onlyUpperCase: { type: Boolean, default: false },
  },
  created() {
    this.inputValue = this.value;
  },
  methods: {
    changeHandler(e) {
      if (this.onlyUpperCase) {
        this.$emit("change-handler", e.target.value.toUpperCase());
        this.$emit("input", e.target.value.toUpperCase());
        this.inputValue = e.target.value.toUpperCase();
      } else {
        this.$emit("change-handler", e.target.value);
        this.$emit("input", e.target.value);
        this.inputValue = e.target.value;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.input-comp-container {
  &.error {
    label {
      input[type="number"],
      input[type="text"],
      input[type="email"],
      input[type="password"] {
        border: 2px solid #b00020;

        &:hover,
        &:focus {
          border: 2px solid #b00020;
        }
      }
    }
  }

  &.no-extra-spacing {
    label {
      margin-bottom: 0;

      input[type="number"],
      input[type="text"],
      input[type="email"],
      input[type="password"] {
        margin-top: 0;
      }
    }
  }

  label {
    display: block;
    margin-bottom: 16px;

    input[disabled] {
      background: #f7f4f4;
      cursor: not-allowed;
    }

    span {
      text-align: left;
      font-weight: 600;
      line-height: 20px;
    }

    input[type="number"],
    input[type="text"],
    input[type="email"],
    input[type="password"] {
      // font-size: 16px;
      font-weight: 400;
      // padding: 8px 12px;
      width: 100%;
      // border-radius: 6px;
      margin: 0px 0 0 0;
      // border: 1px solid #b9c1bd;
      display: block;

      &:focus {
        // border: 1px solid #1ba26f;
      }

      &.error {
        color: #b00020;
        border-color: #b00020;
      }
    }
  }

  .error-label {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 14px;

    span {
      color: #b00020;
      font-weight: 600;
      // font-size: 14px;
      padding-left: 6px;
    }

    img {
      height: 24px;
      width: 24px;
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input::placeholder {
  font-family: "Roboto", serif !important;
  // font-size: 14px;
  color: #999999;
}

input:focus {
  // border: 2px solid #b00020 !important;
  // outline: none !important;
}
</style>
