<template>
    <div class="super-admin-container">
        <div>
            <InputField
                id="filter-name"
                placeholder="Filter Name"
                @change-handler="
                ($event) => {
                    filterName = $event;
                }
                "
            />
            <InputField
                id="filter-key"
                placeholder="Filter Key"
                @change-handler="
                ($event) => {
                    filterKey = $event;
                }
                "
            />
            <InputField
                id="filter-image"
                placeholder="Filter Image"
                @change-handler="
                ($event) => {
                    filterImage = $event;
                }
                "
            />
            <ButtonComp
                :id="'add-filter-butt'"
                :height="'40px'"
                :width="'100%'"
                :color="'#C59B31'"
                :value="'Add Filter'"
                @clickEvent="addFilter()"
            />
        </div>
        <div>
            <div v-for="item in filterList" :key="item._id" :style="getSelectedColor(item.key)" @click="setFilterToRestaurant(item)">
                <div>{{ item.name }}</div>
                <div>{{ item.key }}</div>
                <img :src="item.image"/>
            </div>
        </div>
    </div>
</template>
  
<script>

export default {
    components: {},
    data() {
        return {
            filterName: '',
            filterKey: '',
            filterImage: '',
            filterList: []
        };
    },
    async created() {
        let filterListData = await this.getApiCall('/admin/getFilterList');
        this.filterList = filterListData?.filterList;
        this.filterArray = filterListData?.filterArray;
    },
    methods: {
        async setFilterToRestaurant(item){
            await this.postApiCall('/admin/editFilterList', {filterObject: item});
        },  
        async addFilter() {
            let result = await this.postApiCall('/admin/createFilterList', {name: this.filterName, key: this.filterKey, image: this.filterImage});
            if(result === 'KEY_EXIST'){
                this.errorAlert('Key already Exist');
            } else {
                this.infoAlert('Filter Added');
            }
        },
        getSelectedColor(itemKey) {
            if(this.filterArray.includes(itemKey)){
                return {
                    backgroundColor: 'green'
                }
            }
            return {
                backgroundColor: 'yellow'
            }
        }
    },
    computed: {
        
    }
};
</script>
  

<style lang="scss" scoped>

.button-strip {
    display: flex;
    width: 100%;
    justify-content: space-between;

    .button-div {
      margin: 16px 0px 16px 0px;
      width: calc(50% - 15px);
    }

    // .check-in-button-div {
    //   margin: 0px 16px 16px 16px;
    //   width: 100%;
    //   justify-content: space-between;
    // }
}

.action-butts {
    display: flex;
    width: 30%
}

.order-card {
    padding: 16px;
    border: 1px solid blue;
    border-radius: 5px;
    margin-bottom: 7px;

    .order-card-header {
        display: flex;
        justify-content: space-between;
    }
}

.super-admin-container {
    margin: 16px 16px 0px 16px;
}
</style>