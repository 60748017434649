<template>
  <div>
    <div class="container">
      <div class="map-container-div">
        <div id="map"></div>
      </div>
    </div>
  </div>
</template>

<script>
// import moment from "moment-timezone";
// import { GoogleMap } from 'vue3-google-map';
// import { GmapMap } from 'vue2-google-maps';
// import { onMounted } from "vue";

export default {
  data() {
    return {
      houseImage:
        "https://tumyto-prod-bucket.s3.ap-south-1.amazonaws.com/house.png",
      riderImage:
        "https://tumyto-prod-bucket.s3.ap-south-1.amazonaws.com/rider.png",
      storeImage:
        "https://tumyto-prod-bucket.s3.ap-south-1.amazonaws.com/store.png",
      restaurantList: [],
    };
  },
  async created() {
    this.restaurantList = await this.getApiCall(
      "/superAdmin/getRestaurantList"
    );
    await this.initMap();
  },
  computed: {
    getCenter() {
      return this.center;
    },
  },
  methods: {
    async calculateAndDisplayRoute(directionsService, directionsRenderer, map) {
      directionsService
        .route({
          ...this.directionsOptions,
          origin: this.startLocation,
          destination: this.endLocation,
        })
        .then((response) => {
          new window.google.maps.Marker({
            position: this.startLocation,
            map: map,
            icon: {
              url: this.riderImage,
              scaledSize: new window.google.maps.Size(32, 32), // Scaled size of the icon
            },
          });

          // Add custom end marker
          new window.google.maps.Marker({
            position: this.endLocation,
            map: map,
            icon: {
              url: "https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/geocode-71.png", // Custom end icon URL
              scaledSize: new window.google.maps.Size(32, 32), // Scaled size of the icon
            },
          });

          directionsRenderer.setDirections(response);
        })
        .catch((status) =>
          window.alert("Directions request failed due to " + status)
        );
    },
    async initMap() {
      var icons = {
        start: new window.google.maps.MarkerImage(
          "https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/geocode-71.png",
          new window.google.maps.Size(44, 32),
          new window.google.maps.Point(0, 0),
          new window.google.maps.Point(22, 32)
        ),
        end: new window.google.maps.MarkerImage(
          "https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/geocode-71.png",
          new window.google.maps.Size(44, 32),
          new window.google.maps.Point(0, 0),
          new window.google.maps.Point(22, 32)
        ),
      };

      const { Map } = await window.google.maps.importLibrary("maps");
      let map = new Map(document.getElementById("map"), {
        center: { lat: 22.5744, lng: 88.3629 },
        zoom: 11,
        icons,
        markerOptions: {
          icon: {
            url: "https://path/to/your/custom/icon.png", // Custom icon URL
            scaledSize: new window.google.maps.Size(32, 32), // Scaled size of the icon
          },
        },
        gestureHandling: "greedy",
        mapTypeControl: false,
        fullscreenControl: false,
      });

      this.restaurantList.forEach((data) => {
        new window.google.maps.Marker({
          position: {
            lat: data.location.coordinates[0],
            lng: data.location.coordinates[1],
          },
          map: map,
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  margin: 16px 16px 0px 16px;

  .map-container {
    margin-bottom: 16px;

    // .map-container-div {
    //   z-index: 1;
    //   position: relative;
    // }
  }
}

#map {
  width: 100%;
  height: 400px;
}
</style>
