<template>
  <div class="toggle-switch" @click="toggleSwitch">
    <div
      :class="['toggle-slider', { 'slider-on': switchValue === 'ACTIVE' }]"
    ></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      switchValue: false,
    };
  },
  props: {
    status: {
      type: String,
    },
  },
  created() {
    this.switchValue = this.status;
  },
  methods: {
    toggleSwitch() {
      this.switchBoolean = this.switchValue === "ACTIVE" ? false : true;
      this.switchValue = this.switchValue === "ACTIVE" ? "DEACTIVE" : "ACTIVE";
      this.$emit("change", this.switchValue);
      this.$emit("changeBoolean", this.switchBoolean);
    },
  },
};
</script>

<style lang="scss" scoped>
.toggle-switch {
  width: 60px;
  height: 30px;
  background-color: #ccc;
  border-radius: 15px;
  position: relative;
  cursor: pointer;
}

.toggle-slider {
  width: 28px;
  height: 28px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  top: 1px;
  left: 1px;
  transition: transform 0.2s;
}

.slider-on {
  transform: translateX(30px);
  background-color: green;
}
</style>
