import { io } from "socket.io-client";

const socketId = localStorage.getItem('socketId');
const userData = JSON.parse(localStorage.getItem('userData'));
let URL = process.env.VUE_APP_SOCKET_URL;

const socket = io(URL, {
    query: {
        socketId: socketId || null,
        userId: userData?.userId
    }
});

socket.on('connect', () => {
    localStorage.setItem('socketId', socket.id);
});

socket.on('disconnect', () => {
    localStorage.removeItem('socketId');
});

export default socket;