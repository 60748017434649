<template>
  <div class="button-container">
    <button :style="btnStyles" @click="buttonClicked" :disabled="disabled">
      {{ value }}
    </button>
    <button-confirmation-modal
      v-if="showModal"
      :textToShow="textToShow"
      @booleanTriggered="booleanTriggered"
    ></button-confirmation-modal>
  </div>
</template>

<script>
import ButtonConfirmationModal from "../pages/modals/ButtonConfirmationModal.vue";

export default {
  name: "ButtonComp",
  components: { ButtonConfirmationModal },
  data() {
    return {
      showModal: false,
    };
  },
  props: {
    disabled: { type: Boolean, default: false },
    id: { type: String, required: true },
    type: { type: String, default: "text" },
    value: { type: String, default: "Submit" },
    color: { type: String, default: "crimson" },
    height: { type: String, default: "50px" },
    margin: { type: String, default: "text" },
    width: { type: String, default: "100%" },
    // flex: { type: String, default: "100%" },
    textToShow: { type: String },
  },
  computed: {
    btnStyles() {
      return {
        "background-color": this.color,
        height: `${this.height}`,
        width: `${this.width}`,
        margin: `${this.margin}`,
      };
    },
  },
  created() {},
  methods: {
    buttonClicked() {
      if (this.textToShow) {
        this.showModal = true;
      } else {
        this.$emit("clickEvent");
      }
    },
    booleanTriggered(value) {
      if (value) {
        this.showModal = false;
        this.$emit("clickEvent");
      } else {
        this.showModal = false;
      }
    },
  },
};
</script>

<style lang="scss">
.button-container {
  display: flex;
  //   justify-content: center;
}

// button {
//   padding: 20px !important;
//   border-radius: 20px !important;
//   border: none !important;
//   background-color: rgb(165, 30, 30) !important;
//   color: white !important;
//   margin-top: 2.5rem !important;
// }

button {
  //   padding: 20px;
  border-radius: 5px;
  border: none;
  background-color: v-bind(colour);
  color: white;
  // margin-top: 2.5rem;
}
</style>
