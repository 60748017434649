<template>
  <div class="modal-container">
    <modal-component v-show="show" :isScrollable="true">
      <template v-slot:header>
        <div class="display-flex">
          <div>Create Notifications</div>
          <p @click="turnModalOff()">x</p>
        </div>
      </template>
      <template v-slot:body>
        <div>
          <div>
            <input-field
              type="text"
              :id="'notificationContent'"
              :value="heading"
              @change-handler="
                ($events) => {
                  this.heading = $events;
                }
              "
              placeholder="Notification Heading"
            />
          </div>
          <div>
            <input-field
              type="text"
              :id="'notificationContent'"
              :value="content"
              @change-handler="
                ($events) => {
                  this.content = $events;
                }
              "
              placeholder="Notification Content"
            />
          </div>
          <div>
            <input-field
              type="number"
              :value="hour"
              :id="'hour'"
              @change-handler="
                ($events) => {
                  this.hour = $events;
                }
              "
              placeholder="Trigger Hour"
            />
          </div>
          <div>
            <div
              v-for="notification in notificationList"
              :key="notification._id"
              class="notification-card"
            >
              <div class="notification-card-left">
                <p>Heading: {{ notification.heading }}</p>
                <p>Content: {{ notification.content }}</p>
                <p>Hour: {{ notification.hour }}</p>
              </div>
              <div class="coupon-card-right">
                <toggle-switch
                  :status="notification.status"
                  @change="updateNotificationStatus(notification)"
                />
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="footer-class">
          <div class="action-button">
            <button-comp
              :value="'Store Notification'"
              class="store-coupon-button"
              :height="'40px'"
              :width="'100%'"
              :color="'crimson'"
              @clickEvent="storeNotifications()"
            ></button-comp>
          </div>
        </div>
      </template>
    </modal-component>
  </div>
</template>

<!-- <script src="https://unpkg.com/vue3-google-map"></script> -->
<script>
import { errorAlert, infoAlert } from "@/utility";
// import { GoogleMap, Marker } from "vue3-google-map";

export default {
  data() {
    return {
      selectedDiscountFrequency: "",
      heading: "",
      content: "",
      hour: "",
      notificationList: [],
      restaurantList: [],
      selectedRestaurant: "",
      deductionType: "",
    };
  },
  components: {},
  props: {
    show: { type: Boolean, default: true },
    prefill: { type: Object, default: null },
  },
  computed: {},
  watch: {},
  mounted() {
    this.getNotificationList();
  },
  async created() {},
  methods: {
    changeItemStatus() {},
    regexMatch(coupon) {
      let regex = new RegExp(this.couponCode);
      return regex.test(coupon);
    },
    turnModalOff() {
      this.$emit("turnShowOrderRemoveModalOff");
    },
    async updateNotificationStatus(itemDetails) {
      let toSetStatus = "DEACTIVE";
      if (itemDetails?.status === "DEACTIVE") {
        toSetStatus = "ACTIVE";
      }
      let response = await this.postApiCall(
        "/superAdmin/updateNotficationStatus",
        {
          status: toSetStatus,
          notificationId: itemDetails._id,
        }
      );
      if (response === "NOTIFICATION_UPDATED") {
        infoAlert("Updated Successfully");
      } else {
        errorAlert("Sorry could not update coupon");
      }
    },
    async storeNotifications() {
      let data = {
        heading: this.heading,
        content: this.content,
        hour: this.hour,
      };
      if (this.heading === "" || this.content === "" || this.hour === "") {
        return this.errorAlert("Please add details properly");
      }
      let response = await this.postApiCall(
        "/superAdmin/createNotifications",
        data
      );
      if (response === "NOTIFICATION_STORED") {
        infoAlert("Updated Successfully");
      } else {
        errorAlert("Sorry could not update notification");
      }
    },
    async getNotificationList() {
      let notificationList = await this.getApiCall(
        "/superAdmin/getNotificationList"
      );
      this.notificationList = notificationList;
    },
  },
};
</script>

<style lang="scss" scoped>
.notification-card {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  border: 1px solid grey;
  padding: 16px;
  border-radius: 5px;

  .notification-card-right {
    display: flex;
    // flex-direction: column;
    // align-content: flex-end;
    align-items: end;
    flex-wrap: wrap;
  }
}
</style>
