<template>
  <div>
    <top-navbar
      :topNavbarType="['current-address', 'sidebar', 'login-button']"
    ></top-navbar>
    <div class="container">
      <div v-if="restaurantList && restaurantList.length > 0">
        <div class="search-strip-input" :style="{ display: 'flex' }">
          <div :style="{ flex: '1' }">
            <input-field
              id="search"
              :img="'search.png'"
              :style="{ border: '1px solid #D76E19' }"
              :bindedClass="'search'"
              placeholder="Search...."
              @change-handler="getSearchedList"
            />
          </div>
        </div>
        <div v-if="!showingSearchList" class="line-text">
          <div class="text">Craving For ?</div>
        </div>

        <div v-if="!showingSearchList" class="tag-list-container">
          <div
            v-for="(icon, index) in dishArray"
            :key="icon.tag"
            class="icon"
            @click="activatefilter(index)"
          >
            <img
              :src="icon.image"
              :class="[icon.active ? 'active' : '']"
              alt="Icon"
            />
            <p :style="{ textAlign: 'center', marginTop: 'auto' }">
              {{ icon.name }}
            </p>
          </div>
        </div>
      </div>

      <div
        v-if="
          showInRecommended &&
          showInRecommended.length > 0 &&
          !showingSearchList
        "
      >
        <div class="line-text">
          <div class="text" :style="{ marginTop: '16px' }">
            Tumyto Suggests....
          </div>
        </div>

        <div class="previously-list-container">
          <div :style="{ display: 'flex' }">
            <restaurant-small-card
              v-for="(restaurant, index) in showInRecommended"
              :key="index"
              :data="restaurant"
              :gotoUrl="`/delivery/menu-list/${restaurant._id}`"
            ></restaurant-small-card>
          </div>
        </div>
      </div>

      <!-- <div v-if="previouslyOrderedFrom && previouslyOrderedFrom.length > 0">
        <div class="line-text">
          <div class="line"></div>
          <div class="text">PREVIOUSLY ORDERED</div>
          <div class="line"></div>
        </div>

        <div class="previously-list-container">
          <div :style="{ display: 'flex' }">
            <restaurant-small-card
              v-for="(restaurant, index) in previouslyOrderedFrom"
              :key="index"
              :data="restaurant"
              :gotoUrl="`/delivery/menu-list/${restaurant._id}`"
            ></restaurant-small-card>
          </div>
        </div>
      </div> -->

      <div v-if="restaurantList && restaurantList.length > 0">
        <div class="line-text">
          <div class="text">Restaurant To Explore....</div>
        </div>

        <restaurant-card
          v-for="restaurant in filteredRestaurantList"
          :key="restaurant._id"
          class="restaurat-card-class"
          :restaurant="restaurant"
          :gotoUrl="`/delivery/menu-list/${restaurant._id}`"
        />
      </div>
      <div v-else-if="restaurantList && restaurantList.length === 0">
        <div class="put-in-center">
          <div>
            Please help us find the best eateries around your location.
            <span
              @click="showEaterySuggestionModalFunc()"
              :style="{ color: 'blue' }"
              >Click here</span
            >
          </div>
        </div>
      </div>
    </div>
    <eatery-suggestion
      v-if="showEaterySuggestionModal"
      @turnModalOff="
        () => {
          showEaterySuggestionModal = false;
        }
      "
    ></eatery-suggestion>
  </div>
</template>

<script>
import { errorAlert } from "@/utility";
import RestaurantCard from "../cards/RestaurantCard.vue";
import RestaurantSmallCard from "../cards/RestaurantSmallCard.vue";
import EaterySuggestion from "../modals/EaterySuggestion.vue";
import { dishList } from "../../imports/config.js";

function filterObjectsByTags(stringArray, objectsArray) {
  return objectsArray.filter((obj) => {
    return stringArray.some((tag) => obj.tags.includes(tag));
  });
}

export default {
  name: "HomeComponent",
  data() {
    return {
      restaurantList: [],
      center: {},
      restaurantFilter: [],
      showEaterySuggestionModal: false,
      dishArray: dishList,
      previouslyOrderedFrom: [],
      filteredRestaurantList: [],
      showingSearchList: false,
    };
  },
  components: { RestaurantCard, EaterySuggestion, RestaurantSmallCard },
  async created() {
    let currentAddress = await localStorage.getItem("addressData");
    this.address = JSON.parse(currentAddress);
    // let currCoors;
    if (!this.address) {
      // Check if the Permissions API is supported
      if (navigator.permissions) {
        // Query the permission status for geolocation
        navigator.permissions
          .query({ name: "geolocation" })
          .then(function (result) {
            if (result.state === "granted") {
              console.log("Geolocation permission granted.");
            } else if (result.state === "prompt") {
              console.log("Geolocation permission prompt.");
            } else if (result.state === "denied") {
              errorAlert("Please enable location to discover restaurants.");
            }
          })
          .catch(function (error) {
            console.error("Error querying geolocation permissions:", error);
          });
      } else {
        console.error("Permissions API not supported.");
      }
      let self = this;
      navigator.geolocation.getCurrentPosition(async (position) => {
        self.center.lat = position?.coords.latitude;
        self.center.lng = position?.coords.longitude;
        if (position.coords.latitude) {
          let result = await self.getApiCall("/restaurant/getRestaurantList", {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
          self.restaurantList = result;
          self.filteredRestaurantList = result;
        }
      });
    } else {
      let result = await this.getApiCall("/restaurant/getRestaurantList", {
        lat: this.address?.location.coordinates[1],
        lng: this.address?.location.coordinates[0],
      });
      this.restaurantList = result;
      this.filteredRestaurantList = result;
    }
    await this.getPreviouslyOrderFrom();
  },
  computed: {
    showInRecommended() {
      let recommendedRestaurants = [];

      this.filteredRestaurantList.map((item) => {
        if (item.tags.includes("recommended")) {
          recommendedRestaurants.push(item);
        }
      });
      return recommendedRestaurants;
    },
  },
  methods: {
    getSearchedList(searchText) {
      if (searchText.length === 0) {
        this.showingSearchList = false;
        this.filteredRestaurantList = this.restaurantList;
      } else {
        this.showingSearchList = true;
        let regex = new RegExp(searchText, "i");

        let toSetList = [];

        this.restaurantList.map((item) => {
          if (regex.test(item.name)) {
            toSetList.push(item);
          }
        });

        this.filteredRestaurantList = toSetList;

        // console.log(match); // true if "middle" exists between "start" and "end"
      }
    },
    async getPreviouslyOrderFrom() {
      let previouslyOrderedFrom = await this.getApiCall(
        "/delivery/getPreviouslyOrderFrom"
      );
      this.previouslyOrderedFrom = previouslyOrderedFrom;
    },
    activatefilter(index) {
      let existIndex = this.restaurantFilter.indexOf(this.dishArray[index].tag);
      if (existIndex === -1) {
        this.restaurantFilter.push(this.dishArray[index].tag);
        this.dishArray[index].active = true;
      } else {
        this.restaurantFilter.splice(existIndex, 1);
        this.dishArray[index].active = false;
      }

      if (this.restaurantFilter.length === 0) {
        this.filteredRestaurantList = this.restaurantList;
      } else {
        let filteredObjects = filterObjectsByTags(
          this.restaurantFilter,
          this.restaurantList
        );
        this.filteredRestaurantList = filteredObjects;
      }
    },
    showEaterySuggestionModalFunc() {
      this.showEaterySuggestionModal = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  margin: 1em 1em 0em 1em;

  p {
    font-family: "Montserrat" !important;
  }

  .restaurat-card-class {
    width: 100%;
  }

  .put-in-center {
    position: fixed;
    top: 0;
    left: 1em;
    width: 70%;
    height: 100%;
    // background-color: rgba(0, 0, 0, 0.5);
    /* display: table; */
    transition: opacity 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.line-text {
  display: flex;
  margin-bottom: 0.1em;

  p {
    color: #2e2e28;
    font-family: "Montserrat";
  }

  .line {
    flex-grow: 1;
    height: 1px;
    background-color: #000;
  }

  .text {
    padding: 0px 0px 8px 0px;
    font-family: "Montserrat";
  }
}

.tag-list-container {
  display: flex;
  flex-direction: row;
  overflow-y: scroll;
  flex-wrap: wrap;
}

.previously-list-container {
  display: flex;
  flex-direction: row;
  overflow-y: scroll;
  flex-wrap: wrap;
}

.icon {
  display: flex;
  flex-direction: column;
  flex: 0 0 25%;
  padding: 0.5em;
  box-sizing: border-box;

  img {
    border: 0.1em white solid;
    // border-radius: 50%;
  }

  img.active {
    border: 0.1em red solid;
    // border-radius: 50%;
  }
}

.icon-wrapper {
  width: 50%;
  padding: 10em;
  box-sizing: border-box;
}

.icon img {
  display: block;
  width: 100%;
  height: auto;
}

.tag-list-container::-webkit-scrollbar {
  width: 0;
  height: 0;
}
</style>
