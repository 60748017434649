<template>
  <div class="order-list-container">
    <select v-model="restaurantIdentifier">
      <option
        v-for="(restaurant, index) in restaurantList"
        :key="index"
        :value="restaurant.identifier"
      >
        {{ restaurant.name }}
      </option>
    </select>

    <div>Date Range</div>
    <div>From: {{ this.toShowDateText.from }}</div>
    <div>To: {{ this.toShowDateText.to }}</div>
    <button-comp
      :value="'Show Date Filter'"
      class=""
      :height="'40px'"
      :width="'50%'"
      :color="'#7FBD4F'"
      @clickEvent="
        () => {
          this.showFilter = true;
        }
      "
    ></button-comp>

    <button-comp
      :value="'Get Order List'"
      class="get-orders-button"
      :height="'40px'"
      :width="'100%'"
      :color="'crimson'"
      @clickEvent="getOrderList()"
    ></button-comp>

    <div class="chef-order-list-container">
      <div>
        <custom-date-time-picker-modal
          v-if="showFilter"
          v-model="startDate"
          @selected-range-date="selectedDateRange"
          @turnOffModal="
            () => {
              this.showFilter = false;
            }
          "
          @fetch-list="getFilteredList"
        ></custom-date-time-picker-modal>
      </div>
      <div
        v-for="order in reversedItems"
        :key="order._id"
        :style="{ margin: '16px 0px 0px 0px' }"
      >
        <div>
          {{ order.kotNumber }}
        </div>
        <div>
          {{ order.createdOn }}
        </div>
        <div>Order Id : {{ order.externalOrderId }}</div>
        <div v-if="order.paidOut">
          <p>PAID-OUT</p>
        </div>
        <div>
          <item-list-component :order="order"></item-list-component>
        </div>
        <div>
          <!-- {{ Object.getOwnPropertyNames(order.billInfo) }} -->
          <p>
            Order Price : ₹{{
              order.billInfo?.couponDiscountedFoodBill ||
              order.billInfo?.discountedFoodBill ||
              order.billInfo?.totalFoodBill
            }}
          </p>
          <!-- {{ order.taxAmount }} -->
          <p>Order Tax : ₹{{ order.billInfo?.taxAmount }}</p>
        </div>
      </div>
    </div>
    <div>
      <div class="display-flex">
        <h1>Total Payout</h1>
        <h1>{{ totalPayout }}</h1>
      </div>
      <div class="display-flex">
        <h1>Total Unsettled Payout</h1>
        <h1>{{ totalUnsettledPayout }}</h1>
      </div>
    </div>
    <div>
      <div>
        <button-comp
          @clickEvent="markAsPaidout"
          :value="'Mark as Paid Out'"
          class="set-filter-button"
        />
      </div>
    </div>
    <div>
      <div v-for="restaurant in restaurantPayoutPending" :key="restaurant._id">
        <div>{{ restaurant.restaurantDetails.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import socket from "../../socket";

// let audio;
export default {
  components: {},
  data() {
    return {
      restaurantIdentifier: [],
      orderList: [],
      showList: true,
      showFilter: false,
      startDate: new Date(),
      socket,
      selectedRestaurant: "",
      showPreparedOrder: false,
      toShowDateText: "",
      totalPayout: 0,
      totalUnsettledPayout: 0,
      restaurantList: [],
      restaurantPayoutPending: [],
    };
  },
  async created() {
    this.restaurantList = await this.getApiCall(
      "/superAdmin/getRestaurantList"
    );

    this.restaurantPayoutPending = await this.getApiCall(
      "/superAdmin/getRestaurantListPendingPayout"
    );

    if (this.hasAccessRight("RECIEVE_ORDER")) {
      this.showIncomingOrder = true;
    } else if (this.hasAccessRight("CHANGE_ORDER_STATUS")) {
      this.showAcceptOrder = true;
    }

    this.selectedDateRange();
    await this.getFilteredList();
  },
  computed: {
    getDashboardOrderList() {
      return () => {
        return this.orderList;
      };
    },
    reversedItems() {
      return [...this.orderList].reverse(); // Create a new array to avoid mutating the original array
    },
  },
  methods: {
    async markAsPaidout() {
      let orderIdList = this.orderList.map((item) => {
        return item._id;
      });
      await this.postApiCall("/superAdmin/markAsPaidOut", {
        orderList: orderIdList,
      });
    },
    selectedDateRange(dateData) {
      if (dateData) {
        let fromDateString = `${dateData.from.year}-${dateData.from.month}-${dateData.from.day} ${dateData.from.hour}:${dateData.from.minute}`;
        let toDateString = `${dateData.to.year}-${dateData.to.month}-${dateData.to.day} ${dateData.to.hour}:${dateData.to.minute}`;
        this.filterDateRange = {
          from: moment(fromDateString, "YYYY-MM-DD HH:mm").toDate(),
          to: moment(toDateString, "YYYY-MM-DD HH:mm").toDate(),
        };
      } else {
        let yesterday = moment().subtract("1", "day");
        let today = moment();
        this.filterDateRange = {
          from: moment(yesterday, "YYYY-MM-DD HH:mm").toDate(),
          to: moment(today, "YYYY-MM-DD HH:mm").toDate(),
        };
      }
    },
    async getOrderList() {
      let orderList = await this.getApiCall("/superAdmin/getOrdersForPayout", {
        filterDateRange: this.filterDateRange,
        restaurantIdentifier: this.restaurantIdentifier,
      });
      this.orderList = orderList;
      this.calculateTotalBill();
    },
    async getFilteredList() {
      this.showFilter = false;
      this.toShowDateText = {
        from: moment(this.filterDateRange.from).format("LLLL"),
        to: moment(this.filterDateRange.to).format("LLLL"),
      };
    },
    calculateTotalBill() {
      this.totalPayout = this.orderList
        .map((obj) =>
          parseFloat(
            obj.billInfo?.couponDiscountedFoodBill ||
              obj.billInfo?.discountedFoodBill ||
              obj.billInfo?.totalFoodBill ||
              0
          )
        )
        .reduce((acc, curr) => acc + curr, 0);
      this.totalUnsettledPayout = this.orderList
        .map((obj) => {
          if (!obj.paidOut) {
            return parseFloat(
              obj.billInfo?.couponDiscountedFoodBill ||
                obj.billInfo?.discountedFoodBill ||
                obj.billInfo?.totalFoodBill ||
                0
            );
          } else {
            return 0;
          }
        })
        .reduce((acc, curr) => acc + curr, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
.order-list-container {
  margin: 16px 16px 0px 16px;
}

.button-div {
  margin: 16px 0px 16px 0px;
  width: calc(50% - 5px);
}

.horizontal-pane {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  background-color: #f0f0f0;
  // padding: 10px;
}

.option {
  flex-grow: 1;
  flex-basis: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: hsl(51, 94%, 32%);
  // border-radius: 4px;
  // margin: 0 5px;
  cursor: pointer;
  display: flex;

  p {
    color: #e0e0e0;
    justify-content: center;
    max-width: fit-content;
    margin: 0px 7px;
  }
}

.get-orders-button {
  margin-top: 7px;
}

.horizontal-pane-active {
  background-color: crimson;

  p {
    color: #e0e0e0;
  }
}

.option:hover {
  // background-color: #e0e0e0;
}

/* Adjust the styles as needed */
</style>
