<template>
  <div class="modal-container">
    <modal-component :isScrollable="true">
      <template v-slot:header>
        <p @click="turnModalOff()">x</p>
        <p class="modal-header">Add Menu Item</p>
      </template>
      <template v-slot:body>
        <div>
          <InputField
            id="itemName"
            :value="itemName"
            placeholder="Item Name"
            @change-handler="
              ($event) => {
                itemName = $event;
              }
            "
          />
          <textarea
            id="itemDecp"
            v-model="itemDecp"
            rows="9"
            cols="50"
          ></textarea>

          <InputField
            id="itemPrice"
            placeholder="Item Price"
            :value="itemPrice"
            @change-handler="
              ($event) => {
                itemPrice = $event;
              }
            "
          />
          <InputField
            id="itemImage"
            placeholder="Item Image"
            :value="itemImage"
            @change-handler="
              ($event) => {
                itemImage = $event;
              }
            "
          />
          <InputField
            id="discountPrice"
            placeholder="Discounted Price"
            :value="discountPrice"
            @change-handler="
              ($event) => {
                discountPrice = $event;
              }
            "
          />
          <div :style="{ display: 'flex', flexWrap: 'wrap' }">
            <div
              v-for="item in prefillTags"
              :key="item"
              :style="{ display: 'flex' }"
              @click="tagClicked(item)"
            >
              <input type="checkbox" :checked="idTagChecked(item)" />
              <div :style="{}">{{ item }}</div>
            </div>
          </div>
          <select id="category-select" v-model="itemCategory">
            <option value="" disabled selected>Select Category</option>
            <option
              v-for="category in categories"
              :key="category._id"
              :value="category.identifier"
              :selected="isSelected(category.identifier)"
            >
              {{ category.name }}
            </option>
          </select>
          <div>
            <div
              v-for="item in menuCategoriesArray"
              :key="item"
              :style="{ display: 'flex' }"
              @click="menuCategoryClicked(item)"
            >
              <input
                type="checkbox"
                :checked="idMenuCategoryChecked(item.identifier)"
              />
              <div :style="{}">{{ item.name }}</div>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="button-strip">
          <div class="button-div">
            <ButtonComp
              :id="'call-button'"
              :value="'Add Item'"
              :height="'40px'"
              :width="'100%'"
              :color="'#C59B31'"
              @clickEvent="addMenuItem()"
            />
          </div>
        </div>
      </template>
    </modal-component>
  </div>
</template>

<script>
import { convertToIdentifier, errorAlert, infoAlert } from "../../../utility";
export default {
  data() {
    return {
      show: false,
      categories: [],
      menuCategoriesArray: [],
      menuCategories: [],
      status: "",
      itemName: "",
      itemDecp: "",
      itemPrice: "",
      itemTags: [],
      itemImage: "",
      itemCategory: "",
      discountPrice: "",
    };
  },
  props: {
    categoryForChosenItem: { type: String, default: "" },
    prefillData: { type: Object, default: null },
    prefillTags: { type: Array, default: null },
  },
  created() {
    if (this.prefillData) {
      this.itemName = this.prefillData.name;
      this.itemDecp = this.prefillData.description;
      this.itemPrice = this.prefillData.itemPrice;
      this.itemImage = this.prefillData.itemImage;
      this.discountPrice = this.prefillData.discountPrice;
      this.itemTags = this.prefillData.itemTags || [];
      this.menuCategories = this.prefillData.menuCategories || [];
      this.itemCategory = this.prefillData.categoryIdentifier;
    }
    this.getCustomiseCategories();
    this.getMenuCategories();
  },
  methods: {
    menuCategoryClicked(obj) {
      const index = this.menuCategories?.findIndex(
        (item) => item === obj.identifier
      );
      if (index !== -1) {
        this.menuCategories.splice(index, 1);
      } else {
        this.menuCategories.push(obj.identifier);
      }
    },
    tagClicked(tag) {
      const index = this.itemTags?.findIndex((item) => item === tag);
      if (index !== -1) {
        this.itemTags.splice(index, 1);
      } else {
        this.itemTags.push(tag);
      }
    },
    idTagChecked(item) {
      return this.itemTags?.includes(item);
    },
    idMenuCategoryChecked(obj) {
      const index = this.menuCategories?.findIndex((item) => item === obj);
      if (index === -1) {
        return false;
      }
      return true;
    },
    getTagString() {
      return this.prefillTags.join(" || ");
    },
    isSelected(item) {
      if (convertToIdentifier(item) === this.itemCategory) {
        return true;
      }
      return false;
    },
    isStatusSelected(iStatus, reqStatus) {
      if (iStatus === reqStatus) {
        return true;
      }
      return false;
    },
    turnModalOff() {
      this.$emit("turnEditOrderModalOff");
    },
    async getCustomiseCategories() {
      let result = await this.getApiCall("/admin/getCategoriesForCustomOrder");
      this.categories = result;
    },
    async getMenuCategories() {
      let result = await this.getApiCall("/restaurant/getMenuCategory");
      this.menuCategoriesArray = result;
    },
    async addMenuItem() {
      let name = this.itemName;
      let itemDecp = this.itemDecp;
      let itemPrice = this.itemPrice;
      let itemImage = this.itemImage;
      let discountPrice = this.discountPrice;
      let itemTags = this.itemTags;
      let menuCategories = this.menuCategories;
      let categoryIdentifier = this.itemCategory;
      let result;
      if (this.prefillData && this.prefillData.name) {
        result = await this.postApiCall("/admin/addMenuItem", {
          itemId: this.prefillData._id,
          name,
          itemDecp,
          itemPrice,
          status,
          itemImage,
          discountPrice,
          menuCategories,
          itemTags,
          categoryIdentifier,
        });

        this.$emit("editedObject", {
          _id: this.prefillData._id,
          name,
          description: itemDecp,
          itemPrice,
          status: this.prefillData.status,
          itemImage,
          menuCategories,
          discountPrice,
          itemTags,
          categoryIdentifier,
        });
      } else {
        result = await this.postApiCall("/admin/addMenuItem", {
          name,
          itemDecp,
          itemPrice,
          status,
          itemImage,
          discountPrice,
          itemTags,
          menuCategories,
          categoryIdentifier,
        });
      }

      if (result === "ITEM_ADDED_SUCCESSFULLY") {
        infoAlert("Menu Item Updated");
      } else if (result === "ITEM_ALREADY_EXIST") {
        errorAlert("Item already exist");
      } else {
        errorAlert();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-container {
  height: 500px;
}

.modal-header {
  // font-size: 40px;
}
</style>
