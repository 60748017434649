<template>
    <div class="customization-container">
        <div class="option-list">
            <p @click="
                () => {
                    showAddCategoryModal = true;
                }
            ">
                + Add Category
            </p>
        </div>
        <div>
            <div class="category-card" v-for="(category, index) in customizationList" :key="index"
                @click="setModalAndCategory(category)">
                <h1>{{ category.name }}</h1>
                <div class="tag-slide" v-for="tag in category.tagList" :key="tag.identifier">
                    <p>{{ tag.name }}</p>
                    <p>{{ tag.type }}</p>
                    <p>{{ tag.price }}</p>
                </div>
            </div>
        </div>
        <add-category-modal v-if="showAddCategoryModal" @turnCategoryModalOff="
            () => {
                showAddCategoryModal = false;
            }
        " />
        <add-category-tag-modal v-if="showAddCategoryTagModal" :toEditCategory="toEditCategory" @turnCategoryTagModalOff="
            () => {
                showAddCategoryTagModal = false;
            }
        " />
    </div>
</template>
  
<script>
import AddCategoryModal from "../modals/AddCategoryModal.vue";
import AddCategoryTagModal from "../modals/AddCategoryTagModal.vue";
export default {
    components: { AddCategoryModal, AddCategoryTagModal },
    data() {
        return {
            customizationList: [],
            showAddCategoryModal: false,
            showAddCategoryTagModal: false,
            toEditCategory: {},
        };
    },
    created() {
        this.getCustomizationList();
    },
    methods: {
        setModalAndCategory(category) {
            this.toEditCategory = category;
            this.showAddCategoryTagModal = true;
        },
        async getCustomizationList() {

            let result = await this.getApiCall('/restaurant/getCustomizationListForAdmin');
            this.customizationList = result;

        },
        addCategory() {
            // let categoryName = $("#input-comp-category-name").val();
            // var tagNames = $(".binded-class-tag-name")
            //     .map((i, el) => el.value)
            //     .toArray();
            // let y = {};
            // y.categoryName = categoryName;
            // y.tagNames = tagNames;
            // y.restaurantId = $("#input-comp-restaurant-id").val();
            // Meteor.call("addCategories", y, (err, result) => { });
        },
    },
};
</script>
  
<style lang="scss" scoped>
.customization-container {
    margin: 16px 16px 0px 16px;
    height: 100%;
}

p {
    font-family: serif;
}

.option-list {
    p {
        margin: 5px;
        padding: 5px;
    }
}

.category-card {
    margin: 16px;
    padding: 16px;
    background-color: beige;

    .tag-slide {
        display: flex;
        justify-content: space-between;
    }
}
</style>
  