// import { createApp } from 'vue'
import { createWebHashHistory, createRouter } from "vue-router";
// import UtilityMixin from "./mixin/utilityMixin";
import SuperAdminPanel from "./pages/admin/SuperAdminPanel.vue";
import RestaurantInfoDashboard from "./pages/admin/RestaurantInfoDashboard.vue";
import HappinessDashboard from "./pages/admin/HappinessDashboard.vue";
import CreateFilterList from "./pages/admin/CreateFilterList.vue";
import GetFullOrderDetail from "./pages/admin/GetFullOrderDetail.vue";
import GetGlobalOrderlist from "./pages/admin/GetGlobalOrderlist.vue";
import SuperAdminPayoutDashboard from "./pages/admin/SuperAdminPayoutDashboard.vue";
// import RestaurantDashboard from "./pages/customer/RestaurantDashboard.vue";
import SegmentComponent from "./pages/customer/SegmentComponent.vue";
import RestaurantMapView from "./pages/admin/RestaurantMapView.vue";
// // import RiderHomeMapView from "./pages/rider/RiderHomeMapView.vue";
import HomeComponent from "./pages/customer/HomeComponent.vue";
import AddAddressPage from "./pages/customer/AddAddressPage.vue";
import AcceptDeliveryOrder from "./pages/acceptOrder/AcceptDeliveryOrder.vue";
import MenuList from "./pages/customer/MenuList.vue";
import AddOrderCustomCategory from "./pages/managerDashboard/menu/AddOrderCustomCategory.vue";
// import RestaurantDashboardChartDetail from "./pages/managerDashboard/RestaurantDashboardChartDetail.vue";
// import KitchenViewOrders from "./pages/kitchen/KitchenViewOrders.vue";
// import WaiterViewOrders from "./pages/kitchen/CaptainViewOrders.vue";
// import WaiterViewOrdersTakeaway from "./pages/kitchen/CaptainViewOrdersForTakeaway.vue";
import AddRestraunt from "./pages/customer/AddRestraunt.vue";
import EditRestraunt from "./pages/customer/EditRestraunt.vue";
// import OrderCurrentStatus from "./pages/customer/OrderCurrentStatus.vue";
import ViewPaymentStatus from "./pages/customer/ViewPaymentStatus.vue";
import PolicyPage from "./pages/PolicyPage.vue";
// import AddMenuItem from "./pages/customer/AddMenuItem.vue";
// import RestaurantDetails from "./pages/customer/RestaurantDetails.vue";
// import AddTable from "./pages/customer/AddTable.vue";
// import MyCheckIns from "./pages/customer/MyCheckIns.vue";
import MyOrders from "./pages/customer/MyOrders.vue";
import MyOrderDetail from "./pages/customer/MyOrderDetail.vue";
// import ViewBill from "./pages/customer/ViewBill.vue";
import CheckoutPage from "./pages/customer/CheckoutPage.vue";
// import LiveTableDetails from "./pages/kitchen/LiveTableDetails.vue";
// import GenerateBillV2 from "./pages/captain/GenerateBillV2.vue";
// import EachTableDetails from "./pages/kitchen/EachTableDetails.vue";
// import Login from "./pages/login/Login.vue";
// import Logout from "./pages/Login/Logout.vue";
// // import { wholeStore } from "./store/whole.store";
// import TransferFeature from "./pages/captain/TransferFeature.vue";
// import BillDetail from "./pages/captain/BillDetail.vue";
import OrderDashboard from "./pages/managerDashboard/OrderDashboard.vue";
import EditMenuList from "./pages/managerDashboard/menu/EditMenuList.vue";
// import EditDraggableMenuList from "./pages/managerDashboard/menu/EditDraggableMenuList.vue";
import utilityMixin from "./mixin/utilityMixin";

// Vue.mixin(UtilityMixin);
// Vue.use(VueRouter);

const routes = [
  {
    path: "/policies",
    name: "PolicyPage",
    component: PolicyPage,
  },
  // Manager Routes
  {
    path: "/get-active-orders-admin",
    meta: { transition: "slide-left" },
    name: "SetAccessRights",
    component: SuperAdminPanel,
    beforeEnter: () => {
      return utilityMixin.methods.hasAccessRight("SUPER_ADMIN");
    },
  },
  {
    path: "/hd",
    meta: { transition: "slide-left" },
    name: "HappinessDashboard",
    component: HappinessDashboard,
    beforeEnter: () => {
      return true;
    },
  },
  {
    path: "/map-view",
    meta: { transition: "slide-left" },
    name: "RestaurantMapView",
    component: RestaurantMapView,
    beforeEnter: () => {
      return true;
    },
  },
  {
    path: "/create-default-filter-list",
    meta: { transition: "slide-left" },
    name: "CreateFilterList",
    component: CreateFilterList,
    beforeEnter: () => {
      return utilityMixin.methods.hasAccessRight("SUPER_ADMIN");
    },
  },
  {
    path: "/get-full-order-detail/:incomingOrderId",
    meta: { transition: "slide-left" },
    name: "GetFullOrderDetail",
    component: GetFullOrderDetail,
    beforeEnter: () => {
      return utilityMixin.methods.hasAccessRight("SUPER_ADMIN");
    },
  },
  {
    path: "/set-access-rights",
    meta: { transition: "slide-left" },
    name: "SetAccessRights",
    component: SuperAdminPanel,
    beforeEnter: () => {
      return utilityMixin.methods.hasAccessRight("SUPER_ADMIN");
    },
  },
  {
    path: "/get-global-orderlist",
    meta: { transition: "slide-left" },
    name: "GetGlobalOrderlist",
    component: GetGlobalOrderlist,
    beforeEnter: () => {
      return utilityMixin.methods.hasAccessRight("VIEW_GLOBAL_ORDER_LIST");
    },
  },
  {
    path: "/dashboard-menu-list",
    meta: { transition: "slide-left" },
    name: "EditMenuList",
    component: EditMenuList,
    beforeEnter: () => {
      return utilityMixin.methods.hasAccessRight("RESTAURANT_ADMIN");
    },
  },
  {
    path: "/accept-delivery-order/:currentSection",
    meta: { transition: "slide-left" },
    name: "acceptDeliveryOrder",
    component: AcceptDeliveryOrder,
    beforeEnter: () => {
      return (
        utilityMixin.methods.hasAccessRight("RECIEVE_ORDER") ||
        utilityMixin.methods.hasAccessRight("CHANGE_ORDER_STATUS")
      );
    },
  },
  {
    path: "/add-restraunt",
    meta: { transition: "slide-left" },
    name: "addRestraunt",
    component: AddRestraunt,
    beforeEnter: () => {
      return utilityMixin.methods.hasAccessRight("RESTAURANT_ADMIN");
    },
  },
  {
    path: "/edit-restraunt/:restaurantId",
    meta: { transition: "slide-left" },
    name: "editRestraunt",
    component: EditRestraunt,
    beforeEnter: () => {
      return utilityMixin.methods.hasAccessRight("RESTAURANT_ADMIN");
    },
  },
  {
    path: "/restaurant-info-dashboard",
    meta: { transition: "slide-left" },
    name: "restaurantInfoDashboard",
    component: RestaurantInfoDashboard,
    beforeEnter: () => {
      return utilityMixin.methods.hasAccessRight("SUPER_ADMIN");
    },
  },

  {
    path: "/payout-dashboard",
    meta: { transition: "slide-left" },
    name: "SuperAdminPayoutDashboard",
    component: SuperAdminPayoutDashboard,
  },
  // {
  //   path: "/my-restaurant-details",
  //   name: "Restaurant Dashboard",
  //   component: RestaurantDashboard
  // },
  // {
  //   path: "/my-restaurant-details/table-details/:tableId",
  //   name: "Restaurant Dashboard",
  //   component: DashboardTableDetails
  // },
  {
    path: "/dashboard-order-list",
    meta: { transition: "slide-left" },
    name: "Restaurant Dashboard",
    component: OrderDashboard,
    beforeEnter: () => {
      return utilityMixin.methods.hasAccessRight("RESTAURANT_ADMIN");
    },
  },
  {
    path: "/dashboard-order-customization-edit",
    meta: { transition: "slide-left" },
    name: "Restaurant Dashboard Customization",
    component: AddOrderCustomCategory,
    beforeEnter: () => {
      return utilityMixin.methods.hasAccessRight("RESTAURANT_ADMIN");
    },
  },
  // {
  //   path: "/restaurant-dashboard-view",
  //   name: "Restaurant Dashboard",
  //   component: RestaurantDashboardChartDetail
  // },
  // // Captain Routes
  // {
  //   path: "/v2/show-bill/:orderId",
  //   name: "show-bill",
  //   component: GenerateBillV2
  // },
  // {
  //   path: "/live-table-details",
  //   name: "live-table-details",
  //   component: LiveTableDetails
  // },
  // {
  //   path: "/table-details/:tableId",
  //   name: "Table Details",
  //   component: EachTableDetails
  // },
  // {
  //   path: "/transfer-feature",
  //   name: "Transfer Feature",
  //   component: TransferFeature
  // },
  // {
  //   path: "/bill-detail/:billId",
  //   name: "Bill Detail",
  //   component: BillDetail
  // },
  // // {
  // //   path: "/",
  // //   name: "",
  // //   component: ,
  // // },
  // // {
  // //   path: "/",
  // //   name: "",
  // //   component: ,
  // // },
  // // {
  // //   path: "/",
  // //   name: "",
  // //   component: ,
  // // },
  // // Customer Routes
  // {
  //   path: "/logout",
  //   name: "Logout",
  //   component: Logout
  // },
  // {
  //   path: "/take-away-menu-list/:rid",
  //   name: "TakeAwayRestaurantMenu",
  //   component: MenuList
  // },
  {
    path: "/delivery/menu-list/:rid",
    meta: { transition: "slide-left" },
    name: "DeliveryRestaurantMenu",
    component: MenuList,
    beforeEnter: () => {
      return utilityMixin.methods.hasAccessRight("VIEW_MY_ORDER");
    },
  },
  {
    path: "/customer/add-address",
    meta: { transition: "slide-left" },
    name: "AddAddressPage",
    component: AddAddressPage,
    beforeEnter: () => {
      return utilityMixin.methods.hasAccessRight("VIEW_MY_ORDER");
    },
  },
  // {
  //   path: "/server-order-list",
  //   name: "",
  //   component: WaiterViewOrders
  // },
  // {
  //   path: "/server-order-list-takeaway",
  //   name: "",
  //   component: WaiterViewOrdersTakeaway
  // },
  // // {
  // //   path: "/table-id/:qrTableId/:qrRestaurantId",
  // //   name: "home",
  // //   // eslint-disable-next-line import/no-unresolved, import/extensions
  // //   component: () => import("./pages/")
  // // },
  // {
  //   path: "/menu-list",
  //   name: "MenuList",
  //   component: MenuList
  // },
  // {
  //   path: "/v2/menu-list/:tableId/:restaurantId",
  //   name: "MenuList",
  //   component: MenuList
  // },
  // {
  //   path: "/my-check-ins",
  //   name: "My Check Ins",
  //   component: MyCheckIns
  // },
  // {
  //   path: "/view-bill/:checkInId",
  //   name: "View Bill",
  //   component: ViewBill
  // },
  {
    path: "/my-orders",
    meta: { transition: "slide-left" },
    name: "My Orders",
    component: MyOrders,
    beforeEnter: () => {
      return utilityMixin.methods.hasAccessRight("VIEW_MY_ORDER");
    },
  },
  {
    path: "/my-orders-detail/:orderId",
    meta: { transition: "slide-left" },
    name: "My Order Detail",
    component: MyOrderDetail,
    beforeEnter: () => {
      return utilityMixin.methods.hasAccessRight("VIEW_MY_ORDER");
    },
  },
  {
    path: "/bill-status/:billId",
    meta: { transition: "slide-left" },
    name: "BillStatus",
    component: ViewPaymentStatus,
    beforeEnter: () => {
      // return utilityMixin.methods.hasAccessRight('VIEW_MY_ORDER');
    },
  },
  {
    path: "/delivery-checkout/:orderId",
    meta: { transition: "slide-left" },
    name: "DeliveryCheckout",
    component: CheckoutPage,
    beforeEnter: () => {
      return utilityMixin.methods.hasAccessRight("VIEW_MY_ORDER");
    },
  },
  // {
  //   path: "/order-current-status/:billId",
  //   name: "OrderCurrentStatus",
  //   component: OrderCurrentStatus
  // },
  // {
  //   path: "/restaurant-details",
  //   name: "restaurantDetails",
  //   component: RestaurantDetails
  // },
  // {
  //   path: "/kitchen-view-orders",
  //   name: "restaurant-dashboard",
  //   component: KitchenViewOrders
  // },
  {
    path: "/",
    meta: { transition: "slide-left" },
    name: "login",
    component: SegmentComponent,
    beforeEnter: () => {
      // return utilityMixin.methods.hasAccessRight('VIEW_MY_ORDER');
    },
  },
  {
    path: "/login",
    meta: { transition: "slide-left" },
    name: "login1",
    component: SegmentComponent,
    beforeEnter: () => {
      // return utilityMixin.methods.hasAccessRight('VIEW_MY_ORDER');
    },
  },
  // {
  //   path: "/login-v1",
  //   name: "login2",
  //   component: () => { return import("./pages/login/Login.vue"); }
  // },
  {
    path: "/home",
    meta: { transition: "slide-left" },
    name: "home",
    component: HomeComponent,
    beforeEnter: () => {
      return utilityMixin.methods.hasAccessRight("VIEW_MY_ORDER");
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

// router.beforeEach((to, from) => {
//   // ...
//   // explicitly return false to cancel the navigation
//   return true;
// })

export default router;
