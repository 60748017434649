<template>
  <div>
    <div v-if="showAddAddressDetailComp">
      <AddAddressDetail
        :prefill="prefillData"
        @goback="
          () => {
            turnOffAddAddressModal();
          }
        "
      />
    </div>
    <div v-else id="toRefreshButton">
      <top-navbar
        :topNavbarType="['back-button', 'app-name']"
        :topNavbarData="{ title: 'Add Address' }"
      ></top-navbar>
      <div class="container">
        <ButtonComp
          :value="'Enter complete adress'"
          @clickEvent="checkForLogin()"
        ></ButtonComp>
        <div
          class="address-card"
          v-for="(address, index) in addressList"
          :key="index"
          :style="{ display: 'flex' }"
        >
          <div
            class="address-card-text"
            @click="setCurrentAddress(address)"
            :style="{ flex: 1 }"
          >
            <p class="nameStyle">{{ address.addressName }}</p>
            <p class="addressStyle">
              {{ `${address.buildingAddress} ${address.streetAddress}` }}
            </p>
          </div>
          <div class="address-card-icon">
            <img
              width="20"
              src="/images/pencil.png"
              alt=""
              @click="editAddressSelect(address)"
            />
          </div>
        </div>
      </div>
    </div>
    <LoginModal v-if="showLoginModal" @turnOfLogin="turnOfLogin" />
  </div>
</template>

<script>
// import PullToRefresh from "pulltorefreshjs";
import AddAddressDetail from "./AddAddressDetail.vue";
import LoginModal from "../Login/LoginModal.vue";

export default {
  data() {
    return {
      showAddAddressDetailComp: false,
      restaurantList: [],
      showAddDetailsModal: false,
      prefillData: null,
      addressList: [],
      showLoginModal: false,
    };
  },
  components: { AddAddressDetail, LoginModal },
  computed: {},
  async created() {
    let loginDetails = await localStorage.getItem("userData");

    if (loginDetails) {
      let result = await this.getApiCall("/user/getAddressList");
      this.addressList = result?.address;
    } else {
      this.showLoginModal = true;
    }

    // PullToRefresh.destroyAll();

    // PullToRefresh.init({
    //   mainElement: "#toRefreshButton",
    //   onRefresh() {
    //     window.location.reload();
    //   },
    // });
  },
  methods: {
    async checkForLogin() {
      let loginDetails = await localStorage.getItem("userData");
      if (loginDetails) {
        this.showAddAddressDetailComp = true;
      } else {
        this.showLoginModal = true;
      }
    },
    turnOfLogin() {
      this.showLoginModal = false;
    },
    turnOffAddAddressModal() {
      this.prefillData = null;
      this.showAddAddressDetailComp = false;
    },
    editAddressSelect(address) {
      this.prefillData = address;
      this.showAddAddressDetailComp = true;

      // this.$router.push('/customer/add-address-detail');
    },
    async setCurrentAddress(address) {
      await localStorage.setItem("addressData", JSON.stringify(address));
      this.$router.replace("/home");
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  font-family: serif;
}

.address-card {
  display: flex;
  background-color: #e4e4e4;
  padding: 7px;
  border-radius: 5px;
  justify-content: space-between;
  margin: 16px 0px 0px 0px;
}

.container {
  padding: 16px 16px 16px 16px;

  .address-card-text {
    .nameStyle {
      font-weight: 600;
    }
  }
}
</style>
