<template>
    <div class="modal-container">
        <modal-component v-show="show" :isScrollable="true">
            <template v-slot:header>
                <p @click="turnModalOff()">x</p>
                <p class="modal-header">Referral Detail</p>
            </template>
            <template v-slot:body>
                <input-field type="text" @change-handler="($events) => {
                    referralCode = $events;
                    getReferralsDetail($events)
                }" placeholder="Enter Referral Code" />
                <div v-for="(detail, index) in referralDetail" :key="detail.phoneNumber">

                    <div>
                        {{ index + 1 }}. {{ detail.phoneNumber }}
                    </div>
                </div>
                <div>Total Referrals : &nbsp; {{ referralDetail.length }}</div>
            </template>
            <template v-slot:footer>
                <div class="footer-class">
                    <div class="action-button">
                        <button-comp :value="'Set Referrals Paid Out'" class="add-location-button" :height="'40px'"
                            :width="'100%'" :color="'crimson'" @clickEvent="setReferralsPaidout()"></button-comp>
                    </div>
                </div>
            </template>
        </modal-component>
    </div>
</template>
  
<script>
export default {
    data() {
        return {
            restaurantList: [],
            selectedRestaurant: '',
            phoneNumber: '',
            referralCode: '',
            referralDetail: [],
        };
    },
    props: {
        show: { type: Boolean, default: true },
    },
    methods: {
        turnModalOff() {
            this.$emit('turnShowOrderRemoveModalOff');
        },
        async getReferralsDetail(referralCode) {
            this.referralDetail = await this.getApiCall('/superAdmin/getReferralsDetail', { referralCode });
        },
        async setReferralsPaidout() {
            await this.postApiCall('/superAdmin/setReferralsPaidout', { referralCode: this.referralCode });
        }
    },
};
</script>
  
<style lang="scss" scoped></style>
  