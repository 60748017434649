<template>
    <div :style="{}">
        <div v-for="(item, index) in order.orderData" :key="index" :style="{ padding: '16px', borderRadius: '5px' }">
            <div class="" :style="{ display: 'flex', justifyContent: 'space-between' }">
                <div :style="{ display: 'flex', justifyContent: 'space-between' }">
                    <p>{{ item.itemName }}</p>
                </div>
                <div :style="{ display: 'flex', justifyContent: 'space-between' }">
                    <div v-if="item.discountPrice" class="display-flex">
                        <p>{{ item.count }} x &nbsp;</p>
                        <p>
                            <s>₹ {{ item.itemPrice }}</s>
                        </p> &nbsp;&nbsp; &nbsp;
                        <p>₹ {{ item.discountPrice }}</p>
                    </div>
                    <div v-else class="display-flex">
                        <div class="display-flex">
                            <p>{{ item.count }} x &nbsp;</p>
                            <p>
                                ₹ {{ item.itemPrice }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div v-for="customization in order.orderData[0].customization" :key="customization.name">
                <div class="display-flex">
                            <p>{{ customization.localisedName }}</p>
                            <p>
                                ₹ {{ customization.price }}
                            </p>
                        </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "ItemListComponent",
    props: {
        order: {
            type: Object,
        }
    },
    data() {
        return {

        };
    },
    methods: {
        close() {
            this.$emit("close");
        }
    },
};
</script>
<style type="scss" scoped>
.close {
    /* background-image: url("/cancel.png"); */
    background-size: cover;
    height: 25px;
    width: 25px;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
    color: #fff;
}

.modal-mask {
    position: fixed;
    z-index: 5002;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    transition: opacity 0.3s ease;
}

.modal-mask.no-spacing .modal-content {
    padding: 0;
}

.modal-mask.no-spacing .modal-body {
    padding: 0;
    margin: 0;
}

.modal-wrapper {
    display: table-cell;
    height: 100vh;
    vertical-align: middle;
}

.modal-content {
    position: relative;
    height: 30%;
    width: min(90vw, 500px);
    margin: 0px auto;
    padding: 10px;
    background-color: #fff;
    border-radius: 5px;
    border: 2px solid crimson;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;

    display: flex;
    flex-flow: column;
}

.modal-header span {
    /* font-size: 20px; */
    margin-top: 0;
    flex: 0 1 auto;
}

.modal-body {
    margin: 0px 0px;
    flex: 1 1 auto;
    /* max-height: calc(100% - 210px); */
    /* max-height: 100%;
    overflow-y: auto; */
}

.modal-body-scrollable {
    max-height: 100%;
    overflow-y: auto;
}

.modal-footer {
    /* flex: 0 1 calc(100%-210%); */
}

.modal-default-button {
    float: right;
}

/*
    * The following styles are auto-applied to elements with
    * transition="modal" when their visibility is toggled
    * by Vue.js.
    *
    * You can easily play with the modal transition by editing
    * these styles.
    */

.modal-enter {
    opacity: 0;
}

.modal-leave-active {
    opacity: 0;
}

.modal-enter .modal-content,
.modal-leave-active .modal-content {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

.modal-content.full-screen-modal-content {
    max-width: none;
    width: 90%;
}
</style>
  