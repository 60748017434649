<template>
  <div class="modal-container">
    <modal-component v-show="show" :isScrollable="true">
      <template v-slot:header>
        <div :style="{ display: 'flex', justifyContent: 'space-between' }">
          <h2 class="modal-header">Select Location</h2>
          <p
            @click="
              () => {
                $emit('turnOffModal');
              }
            "
          >
            x
          </p>
        </div>
      </template>
      <template v-slot:body>
        <div class="choose-address-container">
          <button-comp
            @clickEvent="
              () => {
                $emit('gotoAddAddressPage');
              }
            "
            :value="'Click to Add or Edit New Address'"
            class="add-location-button"
          />
          <div
            v-for="address in addressList"
            :key="address.completeAddress"
            @click="setCurrentAddress(address)"
            class="mini-address-card"
          >
            <div class="bold-address-name">{{ address.addressName }}</div>
            <div>
              {{
                `${address.buildingAddress} ${address.streetAddress}`.length >
                32
                  ? `${address.buildingAddress} ${address.streetAddress}`.substring(
                      0,
                      27
                    ) + "..."
                  : `${address.buildingAddress} ${address.streetAddress}`
              }}
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer></template>
    </modal-component>
  </div>
</template>

<script>
import PullToRefresh from "pulltorefreshjs";

export default {
  data() {
    return {
      getAddress: [1, 2, 3],
      addressList: [],
    };
  },
  components: {},
  props: {
    show: { type: Boolean, default: true },
  },
  computed: {
    getAddressList() {
      return [];

      // return Meteor.user().address;
    },
  },
  async created() {
    PullToRefresh.destroyAll();
    let addressList = await this.getApiCall("/user/getAddressList");
    this.$store.dispatch("setStatusBar", false);
    this.addressList = addressList?.address;
  },
  methods: {
    async setCurrentAddress(address) {
      await localStorage.setItem("addressData", JSON.stringify(address));

      // await this.postApiCall('/user/setCurrentAddress', { address });
      // Meteor.call("setCurrentAddress", { address }, function (err, result) {
      //   if (err) {
      //   } else {
      window.location.reload();
      //   }
      // });
    },
  },
  beforeUnmount() {
    PullToRefresh.init({
      mainElement: "body",
      onRefresh() {
        window.location.reload();
      },
    });
  },
};
</script>

<style lang="scss" scoped>
.background-body {
  // background-color: crimson;
}

.bold-address-name {
  font-weight: bolder;
}

.mini-address-card {
  padding: 16px;
  background: #f4f4f4;
  margin-bottom: 10px;
  overflow: hidden;
}

.add-location-button {
  margin-bottom: 10px;
}

.modal-container {
  height: 500px;
}

.modal-header {
  // font-size: 40px;
}

.order-confirm-item-list {
  padding: 20px;
  margin-bottom: 10px;
  background-color: #f4f4f4;
  border-radius: 5px;

  .item-name-quantity-price {
    display: flex;
    justify-content: space-between;
  }

  .display-flex {
    display: flex;
    justify-content: space-between;
  }
}

.footer-class {
  .total-price {
    display: flex;
    justify-content: space-between;

    p {
      // font-size: 25px;
    }
  }

  .button-strip {
    display: flex;
    width: 100%;
    justify-content: space-between;

    .button-div {
      margin: 16px 0px 16px 0px;
      width: calc(50% - 5px);
    }

    .check-in-button-div {
      margin: 0px 16px 16px 16px;
      width: 100%;
      justify-content: space-between;
    }
  }

  .reminder {
    display: flex;
    // font-size: 10px;
    justify-content: center;
    margin-bottom: 10px;
  }
}
</style>
