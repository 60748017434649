<template>
  <div>
    <div class="bottom-navbar">
      <div class="icon-img-label-container">
        <img src="/images/delivery.png" @click="goTo('delivery')" />
        <p>Delivery</p>
      </div>
      <div class="icon-img-label-container">
        <img src="/images/takeaway.png" @click="goTo('takeaway')" />
        <p>Takeaway</p>
      </div>
      <div class="icon-img-label-container">
        <img src="/images/dine.png" @click="goTo('dine')" />
        <p>Dine In</p>
      </div>
      <div class="icon-img-label-container">
        <img src="/images/profile.png" @click="goTo('profile')" />
        <p>Profile</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "BottomNavbar",
  components: {},
  data() {
    return {
      loggedIn: false,
    };
  },
  methods: {
    goTo(goToRoute) {
      switch (goToRoute) {
        case "home":
          this.$router.push(`/home`);
          break;
        case "chat":
          this.$router.push(`chat-list`);
          break;
        case "profile":
          this.$router.push(`profile`);
          break;
        default:
          break;
      }
    },
  },
  created() { },
};
</script>
<style lang="scss" scoped>
.bottom-navbar {
  // border-top: 1px solid indigo;
  // border-radius: 5px;
  // height: 56dp;
  width: 100%;
  bottom: 0px;
  // z-index: 1000;
  padding: 0px 20px 0px 20px;
  position: fixed;
  display: flex;
  justify-content: space-between;
  background-color: #e4e4e4;
}

.icon-img-label-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0px 10px 0px;

  img {
    width: 24px;
  }

  p {
    // font-size: 15px;
  }
}

.bottom-navbar-menu {
  // display: flex;
  // flex: 1;
  // width: 100%;
  // background-color: #e4e4e4;
  // flex-direction: row;
  // justify-content: space-between;
  // margin: 15px 80px 50px 80px;

  img {
    // width: 30px;
  }
}
</style>
