<template>
  <div class="modal-container">
    <modal-component :isScrollable="true">
      <template v-slot:header>
        <div :style="{ display: 'flex', justifyContent: 'space-between' }">
          <h2 class="modal-header">Add Menu Category</h2>
          <p @click="turnModalOff()">x</p>
        </div>
      </template>
      <template v-slot:body>
        <div>
          <div v-for="item in menuCategoryList" :key="item._id">
            <div
              :style="{
                margin: '16px 0px 0px 0px',
                padding: '16px',
                display: 'flex',
                borderRadius: '5px',
                justifyContent: 'space-between',
                backgroundColor: '#efefef',
              }"
              @click="menuCategorySelected(item)"
            >
              <div>{{ item.name }}</div>
            </div>
          </div>
        </div>
      </template>
    </modal-component>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      menuCategoryList: [],
    };
  },
  props: {
    restaurantId: { type: String },
  },
  async created() {
    this.menuCategoryList = await this.getApiCall(
      "/restaurant/getActiveMenuCategories",
      { restaurantId: this.restaurantId }
    );
  },
  methods: {
    menuCategorySelected(item) {
      this.$emit("menuCategorySelected", item);
    },
    turnModalOff() {
      this.$emit("turnCategoryModalOff");
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-container {
  height: 500px;
}

.modal-header {
  // font-size: 40px;
}
</style>
